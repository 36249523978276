import React from 'react';


import { useState as useHookState } from "@hookstate/core";
import { useState, useEffect, useMemo, useRef } from "react";
import GlobalStates from '../../../utils/GlobalStates';
import ConstantStyles from "../../../styles/ConstantStyles.json";
import SiteLogo from '../../../assets/img/bredas_logo.jpg';
import LotteryForms from './LotteryForm/LotteryForms';
import DrawList from '../../../utils/DrawCount.json';
import drawin from '../../../assets/svg/drawin.svg';


import ball_blue from '../../../assets/svg/ball_blue.svg';
import ball_red from '../../../assets/svg/ball_red.svg';
import ball_redblue from '../../../assets/svg/ball_redblue.svg';

import btn_oddeven from '../../../assets/svg/btn_oddeven.svg';
import btn_eveneven from '../../../assets/svg/btn_eveneven.svg';
import btn_oddodd from '../../../assets/svg/btn_oddodd.svg';
import btn_evenodd from '../../../assets/svg/btn_evenodd.svg';

import btn_1_19_19_19 from '../../../assets/svg/btn_1-19_19-19.svg';
import btn_1_19_20_38 from '../../../assets/svg/btn_1-19_20-38.svg';
import btn_20_38_20_38 from '../../../assets/svg/btn_20_38_20-38.svg';
import btn_20_38_1_19 from '../../../assets/svg/btn_20-38_1-19.svg';


import payout_3_50 from '../../../assets/svg/payout-3.50.svg';
import payout_16_80 from '../../../assets/svg/payout-16.80.svg';
import payout_640 from '../../../assets/svg/payout-640.svg';
import payout_1280 from '../../../assets/svg/payout-1280.svg';

import buyticket from '../../../assets/img/buyticket.jpg'
import Cookies from 'js-cookie';
import {
    Button,
    Grid,
    Card,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import VideoSection from './LotteryForm/VideoSection';
import { PostBet, GetPlayersBet, GetCSRF } from '../../../utils/Endpoints'

function upsert(array, element) { // (1)
    const i = array.findIndex(_element => _element.id === element.id);
    if (i > -1) array[i] = element; // (2)
    else array.push(element);
}

const LotterySection = (props) => {
    const myRef = useRef(null);
    const myRef1 = useRef(null);
    const globalStates = useHookState(GlobalStates);
    const [count, setCount] = useState(2);
    const [ticketType, setTicketType] = useState("Single");
    const [checked, setChecked] = useState(true);
    const [draw, setDraw] = useState(null);
    const [clearTicket, setClearTicket] = useState(false);
    const [quickPlay, setQuickPlay] = useState("");
    const [quickPower, setQuickPower] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [payloads, setPayloads] = useState([]);
    const [finalArray, setArray] = useState([]);
    const [total, setTotal] = useState(0);
    const [cards, setCards] = useState([]);
    const [chatFeederUrl, setChatFeederUrl] = useState([]);
    const [open, setOpen] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    // https://chat-feeder.pages.dev/?user_id=7&username=BRCAL035848
    // array

    useEffect(() => {
        tokenSet()
    }, []);

    useEffect(() => {
        if (props.type) {
            setOpen(true)
        }
    }, [props]);

    useEffect(() => {
        setTimeout(() => {
            const arr1 = getUniqueListBy(payloads, "cardNo");
            const sumValues = arr1.reduce((total, obj) => obj.amount + total, 0);
            setTotal(sumValues);
            // console.log(sumValues)
        }, 500);

        setTimeout(() => globalStates.userName.get() && setChatFeederUrl(`https://chat-feeder.pages.dev/?user_id=${globalStates.user_id.get()}&username=${globalStates.userName.get()}`), 100);


    }, [count, payloads, isLoading]);

    useEffect(() => {
        fetchBet()
    }, [isSubmit]);


    function getUniqueListBy(data, key) {
        return [
            ...new Map(data.map((item) => [item[key], item])).values(),
        ];
    }


    const tokenSet = () => {

        GetCSRF()
            .then(response => {
                console.log(response?.data?.data)
                Cookies.set('mb-csrf', response?.data?.data)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                // setIsTableLoading(false);
            });
    }

    const pull_data = (data) => {
        const newArr = [...payloads];
        newArr.push(data);
        upsert(newArr, data);
        setPayloads(newArr);

        // setData(newArr)
        // setTotal(isNaN(sumValues) ? 0 : sumValues);
    }

    const handlePickAll = () => {
        setIsLoading(false)
        let min = 1;
        let max = 38;
        let minPower = 1;
        let maxPower = 38;
        let randomNumber = [];
        let randomNumberMax = [];
        for (let i = 0; i < count; i++) {
            let numbers = [];
            for (let j = 0; j < 2; j++) {
                // console.log(Math.round(Math.random() * (max - min) + min))
                numbers.push(
                    Math.round(Math.random() * (max - min) + min)
                );
            }
            randomNumber.push({ numbers })
        }


        for (let i = 0; i < count; i++) {
            let numbersMax = [];
            numbersMax.push(
                Math.round(Math.random() * (maxPower - minPower) + minPower)
            );
            randomNumberMax.push({ numbersMax })
        }

        setQuickPower(JSON.stringify(randomNumberMax))
        setQuickPlay(JSON.stringify(randomNumber))
        // alert("test")

    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleClearTickets = () => {
        setClearTicket(!clearTicket)
        setPayloads([])
    }

    const modifyCards = (type) => {
        let newCount = count;
        if (type === "More") {
            newCount = count + 1;
            setIsLoading(!isLoading)
        }
        else if (type === "Less") {
            newCount = count - 1;
            setIsLoading(!isLoading)
            const arr1 = getUniqueListBy(payloads, "cardNo");
            let testSet = arr1.map((col, index) => {
                // if (col.index !== newCount) {
                return {

                    cardNo: col.index,
                    amount: col.newAmount,
                    firstNoRed: col.selectedFirstRed,
                    firstNoBlue: col.selectedFirstBlue,
                    straigthBetAmt: col.straigthBetAmt,
                    rumbleBetAmt: col.rumbleBetAmt?.value,
                    singleBallBet: col.singleBallBet?.value,
                    secondNoRed: col.selectedSecondRed,
                    secondNoBlue: col.selectedSecondBlue,
                    singleBet: col.selectedSecond,
                    oddEvenBet: col.oddEvenBet,
                    rangeBet: col.rangeBet,

                }
                // }
                // // else {
                //     return {
                //         cardNo: newCount,
                //         amount: col.newAmount
                //     }
                // }

            });

            if ((testSet.some(testSet => testSet.cardNo === parseFloat(newCount - 1)))) {
                testSet = testSet.filter(function (item) {
                    return item.cardNo !== newCount;
                });
                setPayloads(testSet)
            }
            else {
                setPayloads(arr1)
            }
        }

        setIsLoading(!isLoading)
        setCount(newCount)
        setChecked(!checked)

    }

    const handleSubmit = () => {
        const arr1 = getUniqueListBy(payloads, "cardNo");
        let testSet = arr1.map((col, index) => {
            if (total > 0) {
                return {
                    Card: index + 1,
                    cardNo: col.cardNo,
                    singleBet: { "singleBall": col.singleBet, "type": "Single", "bet": col?.singleBallBet ?? 0 },
                    straigthValue: { "red": col.firstNoRed, "blue": col.firstNoBlue, "type": "Straight", "bet": col?.straigthBetAmt ?? 0 },
                    rumbleValue: { "red": col.secondNoRed, "blue": col.secondNoBlue, "type": "Rumble", "bet": col?.rumbleBetAmt ?? 0 },
                    range: [
                        { "1-19_|_1-19": col.rangebet1 ?? "-", "1-19_|_1-19_Amount": col.rangebet1 ? col.rangebetAmt1 : 0 },
                        { "1-19_|_20-38": col.rangebet2 ?? "-", "1-19_|_20-38_Amount": col.rangebet2 ? col.rangebetAmt2 : 0 },
                        { "20-38_|_20-38": col.rangebet3 ?? "-", "20-38_|_20-38_Amount": col.rangebet3 ? col.rangebetAmt3 : 0 },
                        { "20-38_|_1-19": col.rangebet4 ?? "-", "20-38_|_1-19_Amount": col.rangebet4 ? col.rangebetAmt4 : 0 }
                    ],
                    oddEven: [
                        { "oddEven": col.oddEven1 ?? "-", "oddEven_Amount": col.oddEven1 ? col.oddEvenAmt1 : 0 },
                        { "evenEven": col.oddEven2 ?? "-", "evenEven_Amount": col.oddEven2 ? col.oddEvenAmt2 : 0 },
                        { "oddOdd": col.oddEven3 ?? "-", "oddOdd_Amount": col.oddEven3 ? col.oddEvenAmt3 : 0 },
                        { "evenOdd": col.oddEven4 ?? "-", "evenOdd_Amount": col.oddEven4 ? col.oddEvenAmt4 : 0 }
                    ]

                }
            }
            else {
                return {

                    Card: null,
                    cardNo: col.cardNo,
                    straightRed: undefined,
                    straightBlue: undefined,
                    rumbleBlue: undefined,
                    rumbleRed: undefined,
                    straigthBetAmt: undefined

                }
            }
        });

        if (testSet.some(testSet => testSet.firstNoRed === undefined)) {

            testSet = testSet.filter(function (item) {
                return item.Card !== null;
            });
            setArray(testSet)
        }
        else if (testSet.some(testSet => testSet.firstNoBlue === undefined)) {

            testSet = testSet.filter(function (item) {
                return item.Card !== null;
            });
            // console.log("bl/ue");
            setArray(testSet)
        }
        else if (testSet.some(testSet => testSet.secondNoRed === undefined)) {

            testSet = testSet.filter(function (item) {
                return item.Card !== null;
            });
            setArray(testSet)
        }
        else if (testSet.some(testSet => testSet.secondNoBlue === undefined)) {

            testSet = testSet.filter(function (item) {
                return item.Card !== null;
            });
            setArray(testSet)
        }
        else {
            // alert("Call API");
            setArray(testSet);
        }

        if (total > 0) {
            let submitPayload = {
                userId: "1",
                checkoutReference: Math.round(Math.random() * (9999 - 0) + 0),
                cards: testSet,
                betAmount: parseFloat(total),
                noOfcards: testSet.length,
                draws: draw?.value || 1,
                discount: parseFloat(draw?.discount) || 0,
                drawQty: ticketType
            }
            console.log("Final Payload")
            console.log(submitPayload.cards)
            // setCards(submitPayload.cards)
            // setData(submitPayload)
            submitBet(submitPayload)
        }

        goToLive();

    }

    const submitBet = (payloads) => {
        PostBet(payloads)
            .then(response => {

                globalStates.alertBody.set("Placed");
                globalStates.alertType.set("success");
                globalStates.alertTitle.set("Success");
                globalStates.showAlert.set(true);
                setIsSubmit(!isSubmit)
            })
            .catch(error => {
                console.log(error);
                // setIsLoggingIn(false);
                globalStates.alertType.set("error");
                globalStates.alertTitle.set("Error");
                globalStates.showAlert.set(true);

            });

        setTimeout(() => {
            globalStates.showAlert.set(false);
        }, 1000);
    }

    const fetchBet = () => {
        GetPlayersBet()
            .then(response => {
                console.log(response.data.data.summary)
                setCards(response.data.data.summary)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                // setIsTableLoading(false);
            });
    }

    const renderDraws = useMemo(() => {
        return DrawList.map((col, index) => {
            return { value: col.value, label: col.label, key: index, discount: col.discount }
        });
    }, []);

    // const  = () => {

    // }

    const buyTickets = () => myRef.current.scrollIntoView(); // run this function from an event handler or pass it to useEffect to execute scroll
    const goToLive = () => myRef1.current.scrollIntoView(); // run this function from an event handler or pass it to useEffect to execute scroll
    return (
        <Box
            ref={myRef1}>

            <VideoSection />
            <Grid container
                justifyContent="center"
                spacing={0.5} p={{ xs: 2, sm: 0 }}

                sx={{
                    padding: { xs: "0% 0%", sm: "0% 2%", md: "0% 1%", lg: "0% 1%", xl: "0% 5%" }
                }}
            >
                <Grid item xs={9} sm={9} pb={0} id="section_1" >
                    <Card sx={{ maxWidth: "100%" }}
                        style={{
                            backgroundColor: ConstantStyles.white,
                            height: "490px",
                            overflowY: cards.length > 0 ? "auto" : ""

                        }}>

                        {/* {Object.keys(cards).length > 0 ? ">" : "asdsadsd"}  asd */}
                        {Object.keys(cards).length > 0 ?
                            <>
                                {(() => {
                                    const card = [];
                                    for (let i = 0; i < Object.keys(cards).length; i++) {
                                        card.push(
                                            <>
                                                {/* <p>{JSON.stringify(cards[i + 1][4])} {i + 1}</p> */}
                                                {/* <p>{(cards[i + 1])} {i + 1}</p> */}
                                                <br />
                                            </>
                                        );
                                    }
                                    return card;
                                })()}
                            </>

                            // <>{cards?.map((item, index) => (
                            //     <Grid container
                            //         justifyContent="center"
                            //         spacing={2}
                            //         p={{ xs: 2, sm: 5 }}
                            //         m={5}
                            //         mt={1}
                            //         sx={{
                            //             border: "3px solid gray",
                            //             borderRadius: "10px",
                            //             width: "95%",
                            //             padding: {xs: "0% 0%", sm: "0% 2%", md: "0% 1%", lg: "0% 1%", xl: "0% 5%" }
                            //         }} >
                            //         <Grid item xs={2.4} sm={2.4} pb={0} sx={{ padding: "2%", marginTop: "1%", textAlign: "center" }} >

                            //             {/* red straight*/}
                            //             <div
                            //                 key={index}
                            //                 style={{

                            //                     color: "#222",
                            //                     fontSize: "14px",
                            //                     padding: "2% 1%",
                            //                     backgroundRepeat: "round",
                            //                     width: "auto",
                            //                     display: "inline-block",
                            //                     fontWeight: "bold",
                            //                     marginRight: "5px",
                            //                     position: "relative"

                            //                 }}
                            //             >
                            //                 <img src={ball_red} width={"60px"} height={"60px"} />
                            //                 <p style={{
                            //                     padding: "5%",
                            //                     fontSize: "25px",
                            //                     position: "absolute",
                            //                     top: "12px",
                            //                     right: "0",
                            //                     left: "0",
                            //                     margin: "0 auto",
                            //                     textAlign: "center"
                            //                 }}>{item.straigthValue.red}</p>
                            //             </div>

                            //             {/* blue straight*/}
                            //             <div
                            //                 key={index}
                            //                 style={{

                            //                     color: "#222",
                            //                     fontSize: "14px",
                            //                     padding: "2% 1%",
                            //                     backgroundRepeat: "round",
                            //                     width: "auto",
                            //                     display: "inline-block",
                            //                     fontWeight: "bold",
                            //                     marginRight: "5px",
                            //                     position: "relative"

                            //                 }}
                            //             >
                            //                 <img src={ball_blue} width={"60px"} height={"60px"} />
                            //                 <p style={{
                            //                     padding: "5%",
                            //                     fontSize: "25px",
                            //                     position: "absolute",
                            //                     top: "12px",
                            //                     right: "0",
                            //                     left: "0",
                            //                     margin: "0 auto",
                            //                     textAlign: "center"
                            //                 }}>{item.straigthValue.blue}</p>
                            //             </div>
                            //             {/* bet and pay out - straight */}
                            //             <div style={{ margin: "10% 0" }}>
                            //                 <img src={payout_1280} width={"50%"} />
                            //                 <p style={{
                            //                     width: "50%",
                            //                     display: "inline",
                            //                     backgroundColor: "#eeac20",
                            //                     padding: "2.5% 7%",
                            //                     color: "#fff",
                            //                     border: "3px solid #0774ea",
                            //                     borderRadius: "5px",
                            //                     marginLeft: "5px"
                            //                 }}> BET: {item.straigthValue.bet}</p>
                            //             </div>

                            //             <b style={{ textTransform: "uppercase" }}>{item.straigthValue.type} Bet</b>
                            //         </Grid>
                            //         <Grid item xs={2.4} sm={2.4} pb={0} sx={{ padding: "2%", marginTop: "1%", textAlign: "center" }} >

                            //             {/* Single*/}
                            //             <div
                            //                 key={index}
                            //                 style={{

                            //                     color: "#222",
                            //                     fontSize: "14px",
                            //                     padding: "2% 1%",
                            //                     backgroundRepeat: "round",
                            //                     width: "auto",
                            //                     display: "inline-block",
                            //                     fontWeight: "bold",
                            //                     marginRight: "5px",
                            //                     position: "relative"

                            //                 }}
                            //             >
                            //                 <img src={ball_redblue} width={"60px"} height={"60px"} />
                            //                 <p style={{
                            //                     padding: "5%",
                            //                     fontSize: "25px",
                            //                     position: "absolute",
                            //                     top: "12px",
                            //                     right: "0",
                            //                     left: "0",
                            //                     margin: "0 auto",
                            //                     textAlign: "center"
                            //                 }}>{item.singleBet.singleBall}</p>
                            //             </div>

                            //             {/* bet and pay out - straight */}
                            //             <div style={{ margin: "10% 0" }}>
                            //                 <img src={payout_16_80} width={"50%"} />
                            //                 <p style={{
                            //                     width: "50%",
                            //                     display: "inline",
                            //                     backgroundColor: "#eeac20",
                            //                     padding: "2.5% 7%",
                            //                     color: "#fff",
                            //                     border: "3px solid #0774ea",
                            //                     borderRadius: "5px",
                            //                     marginLeft: "5px"
                            //                 }}> BET: {item.singleBet.bet}</p>
                            //             </div>
                            //             <b style={{ textTransform: "uppercase" }}>{item.singleBet.type} Bet</b>

                            //         </Grid>
                            //         <Grid item xs={2.4} sm={2.4} pb={0} sx={{ padding: "2%", marginTop: "1%", textAlign: "center" }} >

                            //             {/* red rumble*/}
                            //             <div
                            //                 key={index}
                            //                 style={{

                            //                     color: "#222",
                            //                     fontSize: "14px",
                            //                     padding: "2% 1%",
                            //                     backgroundRepeat: "round",
                            //                     width: "auto",
                            //                     display: "inline-block",
                            //                     fontWeight: "bold",
                            //                     marginRight: "5px",
                            //                     position: "relative"

                            //                 }}
                            //             >
                            //                 <img src={ball_red} width={"60px"} height={"60px"} />
                            //                 <p style={{
                            //                     padding: "5%",
                            //                     fontSize: "25px",
                            //                     position: "absolute",
                            //                     top: "12px",
                            //                     right: "0",
                            //                     left: "0",
                            //                     margin: "0 auto",
                            //                     textAlign: "center"
                            //                 }}>{item.rumbleValue.red}</p>
                            //             </div>

                            //             {/* blue rumble*/}
                            //             <div
                            //                 key={index}
                            //                 style={{

                            //                     color: "#222",
                            //                     fontSize: "14px",
                            //                     padding: "2% 1%",
                            //                     backgroundRepeat: "round",
                            //                     width: "auto",
                            //                     display: "inline-block",
                            //                     fontWeight: "bold",
                            //                     marginRight: "5px",
                            //                     position: "relative"

                            //                 }}
                            //             >
                            //                 <img src={ball_blue} width={"60px"} height={"60px"} />
                            //                 <p style={{
                            //                     padding: "5%",
                            //                     fontSize: "25px",
                            //                     position: "absolute",
                            //                     top: "12px",
                            //                     right: "0",
                            //                     left: "0",
                            //                     margin: "0 auto",
                            //                     textAlign: "center"
                            //                 }}>{item.rumbleValue.blue}</p>
                            //             </div>
                            //             {/* bet and pay out - rumble */}
                            //             <div style={{ margin: "10% 0" }}>
                            //                 <img src={payout_640} width={"50%"} />
                            //                 <p style={{
                            //                     width: "50%",
                            //                     display: "inline",
                            //                     backgroundColor: "#eeac20",
                            //                     padding: "2.5% 7%",
                            //                     color: "#fff",
                            //                     border: "3px solid #0774ea",
                            //                     borderRadius: "5px",
                            //                     marginLeft: "5px"
                            //                 }}> BET: {item.rumbleValue.bet}</p>
                            //             </div>

                            //             <b style={{ textTransform: "uppercase" }}>{item.rumbleValue.type} Bet</b>
                            //             {/* <p>{JSON.stringify(item.range)}</p> */}
                            //         </Grid>

                            //         <Grid item xs={2.4} sm={2.4} pb={0} sx={{ padding: "2%", paddingTop: "1%", textAlign: "center" }} >

                            //             <Grid container >
                            //                 <Grid item xs={6} sm={6} pb={1}   >


                            //                     <div style={{
                            //                         position: "relative", width: "auto",
                            //                         display: "inline-block",
                            //                         fontWeight: "bold",
                            //                         marginRight: "5px",
                            //                     }}>
                            //                         <p style={{
                            //                             position: "absolute",
                            //                             textAlign: "center",
                            //                             padding: "2%",
                            //                             fontSize: "25px",
                            //                             position: "absolute",
                            //                             top: "0",
                            //                             right: "0",
                            //                             left: "0",
                            //                             margin: "0 auto",
                            //                             textAlign: "center",
                            //                             backgroundColor: "rgb(154 157 167 / 73%)"
                            //                         }}>{item.oddEven[0].oddEven_Amount}</p>
                            //                         <img src={btn_oddeven} width={"80"} />
                            //                     </div>

                            //                     {/* {item.oddEven?.map((range1, index) => (

                            //                     range1['oddEven'] === "ODD-EVEN" ?
                            //                         <div style={{
                            //                             position: "relative", width: "auto",
                            //                             display: "inline-block",
                            //                             fontWeight: "bold",
                            //                             marginRight: "5px",
                            //                         }}>
                            //                             <p style={{
                            //                                 position: "absolute",
                            //                                 textAlign: "center",
                            //                                 padding: "2%",
                            //                                 fontSize: "25px",
                            //                                 position: "absolute",
                            //                                 top: "0",
                            //                                 right: "0",
                            //                                 left: "0",
                            //                                 margin: "0 auto",
                            //                                 textAlign: "center",
                            //                                 backgroundColor: "rgb(154 157 167 / 73%)"
                            //                             }}>{(range1['oddEven_Amount'])}</p>
                            //                             <img src={btn_oddeven} width={"80"} />
                            //                         </div>

                            //                         :

                            //                         null
                            //                 ))} */}
                            //                 </Grid>

                            //                 <Grid item xs={6} sm={6} pb={1}  >
                            //                     <div style={{
                            //                         position: "relative", width: "auto",
                            //                         display: "inline-block",
                            //                         fontWeight: "bold",
                            //                         marginRight: "5px",
                            //                     }}>
                            //                         <p style={{
                            //                             position: "absolute",
                            //                             textAlign: "center",
                            //                             padding: "2%",
                            //                             fontSize: "25px",
                            //                             position: "absolute",
                            //                             top: "0",
                            //                             right: "0",
                            //                             left: "0",
                            //                             margin: "0 auto",
                            //                             textAlign: "center",
                            //                             backgroundColor: "rgb(154 157 167 / 73%)"
                            //                         }}>{item.oddEven[1].evenEven_Amount}</p>
                            //                         <img src={btn_eveneven} width={"80"} />
                            //                     </div>
                            //                 </Grid>
                            //                 <Grid item xs={6} sm={6} pb={1}  >

                            //                     <div style={{
                            //                         position: "relative", width: "auto",
                            //                         display: "inline-block",
                            //                         fontWeight: "bold",
                            //                         marginRight: "5px",
                            //                     }}>
                            //                         <p style={{
                            //                             position: "absolute",
                            //                             textAlign: "center",
                            //                             padding: "2%",
                            //                             fontSize: "25px",
                            //                             position: "absolute",
                            //                             top: "0",
                            //                             right: "0",
                            //                             left: "0",
                            //                             margin: "0 auto",
                            //                             textAlign: "center",
                            //                             backgroundColor: "rgb(154 157 167 / 73%)"
                            //                         }}>{item.oddEven[2].oddOdd_Amount}</p>
                            //                         <img src={btn_oddodd} width={"80"} />
                            //                     </div>
                            //                 </Grid>

                            //                 <Grid item xs={6} sm={6} pb={1} >

                            //                     <div style={{
                            //                         position: "relative", width: "auto",
                            //                         display: "inline-block",
                            //                         fontWeight: "bold",
                            //                         marginRight: "5px",
                            //                     }}>
                            //                         <p style={{
                            //                             position: "absolute",
                            //                             textAlign: "center",
                            //                             padding: "2%",
                            //                             fontSize: "25px",
                            //                             position: "absolute",
                            //                             top: "0",
                            //                             right: "0",
                            //                             left: "0",
                            //                             margin: "0 auto",
                            //                             textAlign: "center",
                            //                             backgroundColor: "rgb(154 157 167 / 73%)"
                            //                         }}>{item.oddEven[3].evenOdd_Amount}</p>
                            //                         <img src={btn_evenodd} width={"80"} />
                            //                     </div>
                            //                 </Grid>


                            //             </Grid>

                            //             {/* bet and pay out - straight */}
                            //             <div style={{ margin: "1% 0 10% 0%" }}>
                            //                 <img src={payout_3_50} width={"50%"} />
                            //                 <p style={{
                            //                     width: "50%",
                            //                     display: "inline",
                            //                     backgroundColor: "#eeac20",
                            //                     padding: "2.5% 7%",
                            //                     color: "#fff",
                            //                     border: "3px solid #0774ea",
                            //                     borderRadius: "5px",
                            //                     marginLeft: "5px"
                            //                 }}> BET:
                            //                     {parseFloat(item.oddEven[0].oddEven_Amount) + parseFloat(item.oddEven[1].evenEven_Amount) + parseFloat(item.oddEven[2].oddOdd_Amount) + parseFloat(item.oddEven[3].evenOdd_Amount)}</p>

                            //             </div>
                            //             <b style={{ textTransform: "uppercase" }}> Odd-Even Bet</b>
                            //         </Grid>
                            //         <Grid item xs={2.4} sm={2.4} pb={0} sx={{ padding: "1%", textAlign: "center" }} >

                            //             <Grid container >
                            //                 <Grid item xs={6} sm={6} pb={1}   >


                            //                     <div style={{
                            //                         position: "relative", width: "auto",
                            //                         display: "inline-block",
                            //                         fontWeight: "bold",
                            //                         marginRight: "5px",
                            //                     }}>
                            //                         <p style={{
                            //                             position: "absolute",
                            //                             textAlign: "center",
                            //                             padding: "2%",
                            //                             fontSize: "25px",
                            //                             position: "absolute",
                            //                             top: "0",
                            //                             right: "0",
                            //                             left: "0",
                            //                             margin: "0 auto",
                            //                             textAlign: "center",
                            //                             backgroundColor: "rgb(154 157 167 / 73%)"
                            //                         }}>{item.range[0]['1-19_|_1-19_Amount']}</p>
                            //                         <img src={btn_1_19_19_19} width={"80"} />

                            //                     </div>

                            //                 </Grid>

                            //                 <Grid item xs={6} sm={6} pb={1}  >
                            //                     <div style={{
                            //                         position: "relative", width: "auto",
                            //                         display: "inline-block",
                            //                         fontWeight: "bold",
                            //                         marginRight: "5px",
                            //                     }}>
                            //                         <p style={{
                            //                             position: "absolute",
                            //                             textAlign: "center",
                            //                             padding: "2%",
                            //                             fontSize: "25px",
                            //                             position: "absolute",
                            //                             top: "0",
                            //                             right: "0",
                            //                             left: "0",
                            //                             margin: "0 auto",
                            //                             textAlign: "center",
                            //                             backgroundColor: "rgb(154 157 167 / 73%)"
                            //                         }}>{item.range[1]['1-19_|_20-38_Amount']}</p>
                            //                         <img src={btn_1_19_20_38} width={"80"} />
                            //                     </div>
                            //                 </Grid>
                            //                 <Grid item xs={6} sm={6} pb={1}  >

                            //                     <div style={{
                            //                         position: "relative", width: "auto",
                            //                         display: "inline-block",
                            //                         fontWeight: "bold",
                            //                         marginRight: "5px",
                            //                     }}>
                            //                         <p style={{
                            //                             position: "absolute",
                            //                             textAlign: "center",
                            //                             padding: "2%",
                            //                             fontSize: "25px",
                            //                             position: "absolute",
                            //                             top: "0",
                            //                             right: "0",
                            //                             left: "0",
                            //                             margin: "0 auto",
                            //                             textAlign: "center",
                            //                             backgroundColor: "rgb(154 157 167 / 73%)"
                            //                         }}>{item.range[2]['20-38_|_20-38_Amount']}</p>
                            //                         <img src={btn_20_38_20_38} width={"80"} />
                            //                     </div>
                            //                 </Grid>

                            //                 <Grid item xs={6} sm={6} pb={1} >

                            //                     <div style={{
                            //                         position: "relative", width: "auto",
                            //                         display: "inline-block",
                            //                         fontWeight: "bold",
                            //                         marginRight: "5px",
                            //                     }}>
                            //                         <p style={{
                            //                             position: "absolute",
                            //                             textAlign: "center",
                            //                             padding: "2%",
                            //                             fontSize: "25px",
                            //                             position: "absolute",
                            //                             top: "0",
                            //                             right: "0",
                            //                             left: "0",
                            //                             margin: "0 auto",
                            //                             textAlign: "center",
                            //                             backgroundColor: "rgb(154 157 167 / 73%)"
                            //                         }}>{item.range[3]['20-38_|_1-19_Amount']}</p>
                            //                         <img src={btn_20_38_1_19} width={"80"} />
                            //                     </div>
                            //                 </Grid>


                            //             </Grid>

                            //             {/* bet and pay out - straight */}

                            //             <div style={{ margin: "1% 0 8% 0%" }}>
                            //                 <img src={payout_3_50} width={"50%"} />
                            //                 <p style={{
                            //                     width: "50%",
                            //                     display: "inline",
                            //                     backgroundColor: "#eeac20",
                            //                     padding: "2.5% 7%",
                            //                     color: "#fff",
                            //                     border: "3px solid #0774ea",
                            //                     borderRadius: "5px",
                            //                     marginLeft: "5px"
                            //                 }}> BET:
                            //                     {parseFloat(item.range[0]['1-19_|_1-19_Amount']) + parseFloat(item.range[1]['1-19_|_20-38_Amount']) + parseFloat(item.range[2]['20-38_|_20-38_Amount']) + parseFloat(item.range[3]['20-38_|_1-19_Amount'])}</p>

                            //             </div>
                            //             <b style={{ textTransform: "uppercase" }}> Range Bet</b>
                            //         </Grid>
                            //     </Grid>

                            // ))}

                            //     {/* <Grid container
                            //         justifyContent="center"
                            //         spacing={2}
                            //         p={{ xs: 2, sm: 5 }}
                            //         m={5}
                            //         mt={1}
                            //         sx={{
                            //             border: "3px solid gray",
                            //             borderRadius: "10px",
                            //             width: "95%",
                            //             padding: { xs: "0% 0%", sm: "0% 2%", md: "0% 1%", lg: "0% 1%", xl: "0% 5%" }
                            //         }} >

                            //         <Grid item xs={2.4} sm={2.4} pb={0} sx={{ padding: "2%", marginTop: "5%", textAlign: "left", minHeight: "180px", cursor: "pointer" }} >
                            //             <div onClick={buyTickets}>
                            //                 <h3>Click Here</h3>
                            //                 <span>to buy more <u style={{ color: "#5395ff" }}><b>tickets</b></u></span>
                            //             </div>
                            //         </Grid>
                            //     </Grid> */}
                            // </>

                            :
                            <div style={{ cursor: "pointer" }} onClick={buyTickets}>
                                <img src={buyticket} width={"100%"} />
                            </div>
                        }

                    </Card >
                </Grid >
                <Grid item xs={3} sm={3} pb={0} id="section_1"  >
                    <Card
                        sx={{
                            backgroundColor: ConstantStyles.white,
                            color: ConstantStyles['white'],
                            position: "relative",
                            height: "490px"
                            // border: "10px solid #4cc2eb",
                            // borderRadius: "20px"

                        }}>
                        <iframe src={chatFeederUrl} style={{ width: "100%", minHeight: "500px" }} >
                        </iframe>
                    </Card>
                </Grid>

            </Grid >
            <Grid container
                justifyContent="center"
                spacing={0} p={{ xs: 2, sm: 0 }}
                sx={{
                    paddingTop: "25px",
                    display: !isSubmit ? "unset" : "unset"
                }}
            >

                <Grid item xs={12} sm={12} id="section_1" pb={0}>
                    <Card sx={{ maxWidth: "100%" }}
                        style={{
                            backgroundColor: ConstantStyles.white,

                            border: "10px solid #4cc2eb",
                            borderRadius: "20px"

                        }}>
                        <Grid
                            container
                            spacing={2}
                            // p={{ xs: 2, sm: 4 }}
                            sx={{
                                padding: { xs: "2% 0%", sm: "2% 2%", md: "2% 1%", lg: "2% 1%", xl: "2% 5%" },
                                paddingBottom: "0px",
                                backgroundColor: ConstantStyles['white']
                            }}
                        >
                            <Grid item xs={12} sm={2} p={0} sx={{ position: "relative" }} >
                                <img src={SiteLogo} style={{ textAlign: "center", width: "100%", display: "block", margin: "0 auto" }} />
                            </Grid>

                            <Grid item
                                ref={myRef}
                                xs={12} sm={6} mt={{ xs: 1, sm: 1, md: 1, lg: 3 }} style={{ paddingLeft: "0px" }}>
                                <h3 style={{ paddingLeft: "32px", marginTop: "3%" }} >Play Mega 2 Ball Lottery Online – Buy Mega 2 Ball Tickets</h3>
                                <Box sx={{ width: "100%", textAlign: "right", marginTop: { xs: "2%", sm: "2%", md: "2%", lg: "5%" } }}>

                                    <Button size="medium" variant="contained" color="primary"
                                        sx={{
                                            color: ConstantStyles['white'],
                                            display: "inline-block",
                                            textAlign: "center",
                                            padding: { xs: "2px", sm: "2px", md: "2px", lg: "10px 14px" },
                                            width: { xs: "100%", sm: "100%", md: "35%", lg: "25%" },
                                            minHeight: { xs: "75px", sm: "75px", md: "75px", lg: "115px" },
                                            margin: "0 5px",
                                            fontSize: { xs: "18px", sm: "18px", md: "22px", lg: "22px", xl: "32px" },
                                            backgroundColor: ConstantStyles['primary'],
                                            border: "10px solid rgb(76, 194, 235)",
                                            lineHeight: "1",
                                            textTransform: "uppercase",
                                            "&:hover": {
                                                // backgroundColor: ConstantStyles.primary,
                                                color: ConstantStyles.white,
                                                boxShadow: "none"
                                            },
                                        }}
                                        id={"PickAll"}
                                        style={{ backgroundColor: ConstantStyles.secondary }}
                                        onClick={handlePickAll}
                                    >Lucky Pick All</Button>
                                    <Button size="medium" variant="contained" color="primary"
                                        sx={{
                                            color: ConstantStyles['white'],
                                            display: "inline-block",
                                            textAlign: "center",
                                            padding: { xs: "2px", sm: "2px", md: "2px", lg: "10px 14px" },
                                            width: { xs: "100%", sm: "100%", md: "35%", lg: "25%" },
                                            minHeight: { xs: "75px", sm: "75px", md: "75px", lg: "115px" },
                                            margin: "0 5px",
                                            fontSize: { xs: "18px", sm: "18px", md: "22px", lg: "22px", xl: "32px" },
                                            backgroundColor: ConstantStyles['primary'],
                                            border: "10px solid rgb(76, 194, 235)",
                                            lineHeight: "1",
                                            textTransform: "uppercase",
                                            marginRight: "30px",
                                            "&:hover": {
                                                // backgroundColor: ConstantStyles.primary,
                                                color: ConstantStyles.white,
                                                boxShadow: "none"
                                            },
                                        }}
                                        onClick={handleClearTickets}
                                    > Clear</Button>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={4} p={0} sx={{ position: "relative", textAlign: "center" }} >

                                <Box
                                    sx={{
                                        color: "#222",
                                        padding: "2% 1%",
                                        width: "75%",
                                        display: "inline-block",
                                        marginRight: "5px",
                                        height: "100%",
                                        fontWeight: "bold",
                                        backgroundImage: `url(${drawin})`,
                                        backgroundRepeat: "no-repeat",
                                        fontSize: { xs: "70px", sm: "70px", md: "70px", lg: "100px" },
                                        fontWeight: "bold",
                                        lineHeight: "2.7"
                                    }}
                                >
                                    <iframe scrolling="no" style={{ height: "100px", overflowY: "hidden" }} src={"https://timer.megabingo.ph/"}></iframe>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container
                            // spacing={1}
                            sx={{
                                padding: { xs: "2% 0%", sm: "2% 2%", md: "2% 1%", lg: "2% 1%", xl: "2% 5%" },
                            }}
                        >
                            {(() => {
                                const arr = [];
                                for (let i = 0; i < count; i++) {
                                    arr.push(
                                        <LotteryForms index={i} isLoading={isLoading} checked={checked} clearTicket={clearTicket} quickPower={quickPower} quickPlay={quickPlay} newCount={count} func={pull_data} isSubmit={isSubmit} />
                                    );
                                }
                                return arr;
                            })()}
                        </Grid>

                        {/* <Grid container spacing={1} p={{ xs: 2, sm: 4 }} style={{ backgroundColor: ConstantStyles['white'] }} >
                        <Grid item xs={12} sm={12} style={{ border: ConstantStyles['default-border'] }}>
                            <p
                                style={{
                                    fontWeight: ConstantStyles['bold'],
                                    fontSize: "15px"
                                }}
                            >Choose the type of ticket</p>

                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={ticketType}
                                    onChange={ticketTypeHandle}
                                >
                                    <FormControlLabel value="Single" control={<Radio
                                    />} label="Single" />
                                    <FormControlLabel value="Multiple" control={<Radio
                                    />} label="Multiple" />
                                </RadioGroup>
                            </FormControl>
                            {ticketType === "Multiple" ?
                                <div style={{ marginBottom: "20px", width: "95%", padding: "0 15px" }}>
                                    <CustomAutocompleteSelect
                                        options={renderDraws}
                                        value={draw}
                                        key={renderDraws.index}
                                        onChange={setDraw}
                                        placeholder={"Multiple Draws"}
                                    />
                                </div>
                                :
                                null
                            }

                        </Grid>

                    </Grid> */}

                        <Grid container justifyContent={"center"} p={{ xs: 2, sm: 4 }} sx={{ backgroundColor: ConstantStyles['white'] }} >
                            <Grid item xs={12} sm={12} md={10}
                                sx={{ textAlign: { sx: "center", sm: "center" } }}
                            >

                                <Button size="medium" variant="" color=""
                                    sx={{
                                        color: ConstantStyles['white'],
                                        display: "inline-block",
                                        textAlign: "center",
                                        padding: "10px 14px",
                                        width: { xs: "100%", sm: "100%", md: "20%" },
                                        margin: "0 5px",
                                        fontSize: { xs: "18px", sm: "18px", md: "28px", lg: "28px", xl: "38px" },
                                        backgroundColor: count === 1 ? "rgba(118, 118, 118, 0.6)" : "rgb(227 30 15)",
                                        border: "10px solid rgb(76, 194, 235)",
                                        lineHeight: "1",
                                        textTransform: "uppercase",
                                        minHeight: "115px",
                                        cursor: count === 1 ? "not-allowed" : "pointer",
                                        "&:hover": {
                                            backgroundColor: "#bb1205",
                                            color: ConstantStyles.white,
                                            boxShadow: "none",
                                            cursor: count === 1 ? "not-allowed" : "pointer",
                                        },
                                    }}
                                    style={{
                                        color: ConstantStyles['white']
                                    }}
                                    disabled={count === 1 ? true : false}
                                    onClick={(e) => modifyCards("Less")}
                                > - Lessen Cards</Button>

                                <Button size="medium" variant="contained" color="primary"
                                    sx={{
                                        color: ConstantStyles['white'],
                                        display: "inline-block",
                                        textAlign: "center",
                                        padding: "10px 14px",
                                        width: { xs: "100%", sm: "100%", md: "20%" },
                                        margin: "0 5px",
                                        fontSize: { xs: "18px", sm: "18px", md: "28px", lg: "28px", xl: "38px" },
                                        backgroundColor: "rgb(13 74 242)",
                                        border: "10px solid rgb(76, 194, 235)",
                                        lineHeight: "1",
                                        textTransform: "uppercase",
                                        minHeight: "115px",
                                        "&:hover": {
                                            backgroundColor: "rgb(16 55 163)",
                                            color: ConstantStyles.white,
                                            boxShadow: "none"
                                        },
                                    }}
                                    style={{
                                        color: ConstantStyles['white']
                                    }}
                                    onClick={(e) => modifyCards("More")}
                                > + More Cards</Button>


                                <Button size="medium" variant="contained" color="primary"
                                    // onClick={handleSubmit}
                                    sx={{
                                        color: ConstantStyles['white'],
                                        display: "inline-block",
                                        textAlign: "center",
                                        padding: "10px 14px",
                                        width: { xs: "100%", sm: "100%", md: "20%" },
                                        margin: "0 5px",
                                        fontSize: { xs: "18px", sm: "18px", md: "28px", lg: "28px", xl: "38px" },
                                        backgroundColor: "rgb(0 99 0)",
                                        border: "10px solid rgb(76, 194, 235)",
                                        lineHeight: "1",
                                        textTransform: "uppercase",
                                        minHeight: "115px",
                                        cursor: total > 0 ? "pointer" : "not-allowed",
                                        "&:hover": {
                                            backgroundColor: "rgb(0 99 0)",
                                            color: ConstantStyles.white,
                                            boxShadow: "none",
                                            cursor: "unset",
                                            cursor: total > 0 ? "pointer" : "not-allowed"
                                        },
                                    }}
                                    style={{
                                        color: ConstantStyles['white']
                                    }}
                                > Total:  {total}</Button>

                                <Button size="medium" variant="contained" color="primary"
                                    onClick={handleSubmit}
                                    sx={{
                                        color: ConstantStyles['white'],
                                        display: "inline-block",
                                        textAlign: "center",
                                        padding: "10px 14px",
                                        width: { xs: "100%", sm: "100%", md: "20%" },
                                        margin: "0 5px",
                                        fontSize: { xs: "18px", sm: "18px", md: "28px", lg: "28px", xl: "38px" },
                                        backgroundColor: ConstantStyles['primary'],
                                        border: "10px solid rgb(76, 194, 235)",
                                        lineHeight: "1",
                                        textTransform: "uppercase",
                                        minHeight: "115px",
                                        "&:hover": {
                                            // backgroundColor: ConstantStyles.primary,
                                            color: ConstantStyles.white,
                                            boxShadow: "none"
                                        },
                                    }}
                                    style={{
                                        color: ConstantStyles['white']
                                    }}

                                    disabled={total > 0 ? false : true}
                                > Confirm
                                </Button>


                            </Grid>

                            {/* <Grid item xs={12} sm={6} style={{ textAlign: "right", marginTop: "10px" }}>
                        </Grid> */}

                        </Grid>
                        <br />

                    </Card>
                </Grid >
            </Grid >


            <Dialog
                sx={{
                    "& .MuiDialog-container": {
                        alignItems: "flex-start"
                    },
                }}
                PaperProps={{ sx: { mt: "100px", width: "100%" } }}
                open={open}
                onClose={handleClose}

                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {props.type === "win" ? <h2>Winner(s)!</h2> : null}
                    {props.type === "welcome" ? <h2>Welcome!</h2> : null}
                    {props.type === "congratulations" ? <h2>Congratulations!</h2> : null}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: "#222", textAlign: "center" }}>
                        <img src={props.gif} />
                        <h3>{props.message}</h3>
                        <ul style={{ listStyleType: "none" }}>
                            {props?.players?.map((user, index) => (
                                <li>{user}</li>
                            ))}
                        </ul>
                    </DialogContentText>
                </DialogContent>
                {/* <DialogActions>
                    <CustomButton
                        buttonLabel={"Cancel"}
                        type="submit"
                        onClick={() => {
                            handleClose()
                        }}
                        disabled={isLoggingIn}
                        customSX={{
                            width: "auto",
                            padding: "11px",
                            color: ConstantStyles.black,
                            border: ConstantStyles['default-border'],
                            backgroundColor: ConstantStyles.white,
                            verticalAlign: "top",
                            "&:hover": {
                                backgroundColor: ConstantStyles.primary,
                                color: ConstantStyles.white,
                                boxShadow: "none"
                            },
                        }}
                    />
                    {type === "Withdraw" ?
                        <CustomButton
                            buttonLabel={"Submit"}
                            onClick={() => {
                                uploadImg()
                            }}
                            type="submit"
                            disabled={isLoggingIn}
                            customSX={{
                                width: "auto",
                                padding: "11px",
                                color: ConstantStyles.white,
                                border: ConstantStyles['default-border'],
                                verticalAlign: "top",
                                "&:hover": {
                                    backgroundColor: ConstantStyles.primary,
                                    color: ConstantStyles.white,
                                    boxShadow: "none"
                                },
                            }}
                        /> :
                        <CustomButton
                            buttonLabel={"Submit"}
                            onClick={() => {
                                handleDeposit()
                            }}
                            type="submit"
                            disabled={isLoggingIn}
                            customSX={{
                                width: "auto",
                                padding: "11px",
                                color: ConstantStyles.white,
                                border: ConstantStyles['default-border'],
                                verticalAlign: "top",
                                "&:hover": {
                                    backgroundColor: ConstantStyles.primary,
                                    color: ConstantStyles.white,
                                    boxShadow: "none"
                                },
                            }}
                        />}
                </DialogActions> */}
            </Dialog>
        </Box >
    )


}


export default LotterySection;