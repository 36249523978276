import { useState, useEffect, useMemo } from "react";
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../../utils/GlobalStates';
import ConstantStyles from "../../../../styles/ConstantStyles.json";
import VideoSample from '../../../../assets/img/sampleVid.mp4';

import {
    Button,
    Grid,
    Card,
    Box
} from '@mui/material';

const VideoSection = () => {
    const globalStates = useHookState(GlobalStates);

    // useEffect(() => {
    //     console.log(username)
    //     globalStates.userName.set(username);
    // }, [token]);

    return (
        <Box>

            <Grid container
                justifyContent="center"
                spacing={0}
                sx={{
                    padding: { xs: "1% 0%", sm: "1% 2%", md: "1% 1%", lg: "1% 1%", xl: "1% 5%" }
                }}
            >
                <Grid item xs={12} sm={12} style={{ textAlign: "center", position: "relative" }}>
                    <div style={{ position: "relative" }}>
                        <video src={VideoSample} controls="true" height={"500px"} autoplay="true" style={{ width: "100%" }} />
                    </div>
                </Grid>
            </Grid>
        </Box>
    )


}


export default VideoSection;