import { useState, useEffect } from "react";
import { useState as useHookState } from "@hookstate/core";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import Cookies from 'js-cookie';
// shared components
import CustomAlert from "./components/shared/CustomAlert";
import CustomCircularProgress from './components/shared/CustomCircularProgress';
// components
import Header from './components/shared/general/Header';
import Footer from './components/shared/general/Footer';

// modules
import Homepage from './components/modules/Home/Homepage';
// style 
import ThemeStyles from "./styles/ThemeStyles";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import socketIO from "socket.io-client"
// global states
import GlobalStates from './utils/GlobalStates';
// general components

const socket = socketIO.connect("https://socket.megabingo.ph/megatwoball-events");
function App(props) {
	const globalStates = useHookState(GlobalStates);

	const [theme, setTheme] = useState(ThemeStyles);

	useEffect(() => {

		if (!Cookies.get('session-1') || Cookies.get('session-1') === "") {
			localStorage.clear()
		}

		if (window.screen.width <= 640) {
			globalStates.isMobile.set(true);
		}
		else {
			globalStates.isMobile.set(false);
		}
	}, [window.screen.width]);

	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter basename={props.baseUrl}>
				<Routes>
					<Route path="/game/:token/:username/:user_id" element={<Homepage socket={socket} />} />
				</Routes>
			</BrowserRouter>
			<CustomAlert />
		</ThemeProvider>
	);
}

export default App;
