import { useState } from "react";
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../utils/GlobalStates';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


import ConstantStyle from '../../../styles/ConstantStyles.json'

import navItems from '../../../utils/NavItems.json';

import bag from '../../../assets/svg/money-bag-icon.svg';
import Notification from '../../../assets/svg/Notification.svg';

const drawerWidth = 240;
export default function DrawerAppBar(props) {
    const globalStates = useHookState(GlobalStates);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [noCart, setNoCart] = useState(0);
    const [betAmount, setBetAmount] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const container = "";


    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Divider />
            <List>
                {navItems.map((item, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );


    return (
        <Box sx={{ display: 'block' }}>
            <CssBaseline />
            <AppBar component="nav" style={{ background: ConstantStyle["headerBg"], color: ConstantStyle["white"], boxShadow: "unset", boxShadow: ConstantStyle['default-box-shadow'], position: "fixed" }}>
                <Toolbar>

                    <IconButton
                        color="plain"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon htmlColor={ConstantStyle.black} />
                    </IconButton>


                    <Button sx={{
                        display: { sm: 'none' },
                        backgroundColor: ConstantStyle["white"],
                        color: ConstantStyle["black"],
                        margin: { xs: '0 8px', sm: '0 8px', md: '0 8px', xl: '0 8px' },
                        fontSize: "16px",
                        fontWeight: "normal",
                        "&:hover": {
                            backgroundColor: ConstantStyle["white"]
                        }
                    }}>
                        <div style={{ position: "relative", display: "inline-block", float: "right", marginRight: "10px" }} onClick={() => window.location.href = "/cart"}>
                            <span style={{
                                background: "green",
                                color: ConstantStyle.white,
                                width: "100%",
                                height: "85%",
                                borderRadius: "50%",
                                display: "block",
                                top: "-10px",
                                left: "15px",
                                position: "absolute",
                                fontSize: "13px"

                            }} onClick={() => window.location.href = "/cart"}>{noCart}</span>
                            <ShoppingCartIcon
                                fontSize='medium'
                            />
                        </div>
                    </Button>

                    <Box sx={{ flexGrow: 2, display: { xs: 'none', md: 'block' } }}>
                        <Grid container spacing={2} p={{ xs: 2, sm: 8 }} style={{ borderBottom: ConstantStyle['default-border'], paddingTop: "5px", paddingBottom: "5px" }} >
                            <Grid item xs={12} sm={4} >
                            </Grid>
                            <Grid item xs={12} sm={8} >
                                <Grid container sx={{ textAlign: "right" }} spacing={2} p={{ xs: 2, sm: 2, md: 1 }} >
                                    <Grid item xs={12} sm={12} >
                                        {globalStates.userName.get() ?
                                            <>
                                                <Grid container spacing={0} >
                                                    <Grid item xs={12} sm={7} xl={8.7} >

                                                        <Button
                                                            onClick={() => {
                                                                // window.location.href = "/account";
                                                            }}
                                                            sx={{
                                                                minWidth: "auto",
                                                                // padding: "10px 0",
                                                                backgroundColor: "#000066",
                                                                color: ConstantStyle["white"],
                                                                margin: { xs: '0 5px', sm: '0 5px', md: '0 5px', xl: '0 5px' },
                                                                fontSize: "14px",
                                                                fontWeight: "normal",
                                                                "&:hover": {
                                                                    color: ConstantStyle["white"],
                                                                    backgroundColor: "#000066"
                                                                }
                                                            }}
                                                        >
                                                            Account #&nbsp;:&nbsp;<b> {globalStates.userName.get()}</b>
                                                        </Button>
                                                    </Grid>

                                                    <Grid item xs={12} sm={2} xl={1.2}
                                                        sx={{
                                                            textAlign: "left"
                                                        }} >

                                                        <Button
                                                            onClick={() => {
                                                                // window.location.href = "/account";
                                                            }}
                                                            sx={{
                                                                minWidth: "auto",
                                                                // padding: "10px 0",
                                                                backgroundColor: "inherit",
                                                                color: ConstantStyle["white"],
                                                                margin: { xs: '0 5px', sm: '0 5px', md: '0 5px', xl: '0 5px' },
                                                                fontSize: "14px",
                                                                fontWeight: "normal",
                                                                "&:hover": {
                                                                    color: ConstantStyle["white"],
                                                                    backgroundColor: "#inherit"
                                                                }
                                                            }}
                                                        >
                                                            <img src={bag} width={"16"} />&nbsp;50,000.00
                                                        </Button>
                                                    </Grid>

                                                    <Grid item xs={12} sm={2} xl={1}
                                                        sx={{
                                                            textAlign: "left",
                                                            borderLeft: "1px solid #fff",
                                                        }} >


                                                        <div>
                                                            <Button
                                                                id="basic-button"
                                                                aria-controls={open ? 'basic-menu' : undefined}
                                                                aria-haspopup="true"
                                                                aria-expanded={open ? 'true' : undefined}

                                                                sx={{
                                                                    minWidth: "auto",
                                                                    backgroundColor: "inherit",
                                                                    color: ConstantStyle["white"],
                                                                    margin: { xs: '0 5px', sm: '0 5px', md: '0 5px', xl: '0 5px' },
                                                                    fontSize: "14px",
                                                                    fontWeight: "normal",
                                                                    "&:hover": {
                                                                        color: ConstantStyle["white"],
                                                                        backgroundColor: "#inherit"
                                                                    }
                                                                }}
                                                                onClick={handleClick}
                                                            >
                                                                {globalStates.userName.get()}&nbsp;
                                                            </Button>
                                                            <Menu
                                                                id="basic-menu"
                                                                anchorEl={anchorEl}
                                                                open={open}
                                                                onClose={handleClose}
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'basic-button',
                                                                }}
                                                                PaperProps={{
                                                                    style: {
                                                                        marginTop: "12px",
                                                                        background: ConstantStyle["headerBg"],
                                                                        color: "#fff"
                                                                    }
                                                                }}
                                                            >
                                                                <MenuItem onClick={handleClose}>PROFILE</MenuItem>
                                                                <MenuItem onClick={handleClose}>DEPOSIT</MenuItem>
                                                                <MenuItem onClick={handleClose}>WITHDRAW</MenuItem>
                                                                <MenuItem onClick={handleClose}>LOGOUT</MenuItem>
                                                            </Menu>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={1}
                                                        sx={{
                                                            textAlign: "left",
                                                        }} >

                                                        <Button
                                                            onClick={() => {
                                                                // window.location.href = "/account";
                                                            }}
                                                            sx={{
                                                                minWidth: "auto",
                                                                padding: "0",
                                                                backgroundColor: "inherit",
                                                                color: ConstantStyle["white"],
                                                                margin: { xs: '0 5px', sm: '0 10px', md: '0 10px', xl: '0 15px' },
                                                                fontSize: "14px",
                                                                fontWeight: "normal",
                                                                "&:hover": {
                                                                    color: ConstantStyle["white"],
                                                                    backgroundColor: "#inherit"
                                                                }
                                                            }}
                                                        >
                                                            &nbsp;&nbsp;<img src={Notification} width={"24"} />
                                                        </Button>
                                                    </Grid>



                                                </Grid>

                                            </>
                                            :
                                            <Button
                                                onClick={() => {
                                                    window.location.href = "/login";
                                                }}
                                                sx={{
                                                    minWidth: "130px",
                                                    padding: "10px 0",
                                                    backgroundColor: ConstantStyle.secondary,
                                                    color: ConstantStyle["white"],
                                                    margin: { xs: '0 5px', sm: '0 5px', md: '0 5px', xl: '0 5px' },
                                                    fontSize: "16px",
                                                    fontWeight: "normal",
                                                    "&:hover": {
                                                        backgroundColor: ConstantStyle.secondary
                                                    }
                                                }}
                                            >
                                                Login/Register
                                            </Button>
                                        }
                                        {/* <Button sx={{
                                            minWidth: "130px",
                                            padding: "10px 0",
                                            backgroundColor: ConstantStyle.primary,
                                            color: ConstantStyle["white"],
                                            margin: { xs: '0 8px', sm: '0 8px', md: '0 8px', xl: '0 8px' },
                                            fontSize: "16px",
                                            fontWeight: "normal",
                                            "&:hover": {
                                                backgroundColor: ConstantStyle.primary
                                            }
                                        }}>
                                            Sign up
                                        </Button> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Toolbar>
                <Toolbar sx={{
                    display: { sm: 'none' },
                    backgroundColor: ConstantStyle['grey'],
                    padding: "15px",
                    textAlign: "center"
                }}>

                    {globalStates.userName.get() ?

                        <div style={{ display: "block", width: "100%", margin: "0 auto" }}>
                            <Button
                                onClick={() => {
                                    window.location.href = "/account";
                                }}
                                sx={{
                                    minWidth: "auto",
                                    // padding: "10px 0",
                                    backgroundColor: "transparent",
                                    color: ConstantStyle["black"],
                                    margin: { xs: '0 5px', sm: '0 5px', md: '0 5px', xl: '0 5px' },
                                    fontSize: "16px",
                                    fontWeight: "normal",
                                    "&:hover": {
                                        color: ConstantStyle["white"],
                                        backgroundColor: ConstantStyle.secondary
                                    }
                                }}
                            >
                                Account #: <b> {globalStates.userName.get()}</b>
                            </Button>
                        </div>
                        :
                        <div style={{ display: "block", width: "100%", margin: "0 auto" }}>
                            <Button
                                onClick={() => {
                                    window.location.href = "/login";
                                }}
                                sx={{
                                    minWidth: "130px",
                                    padding: "10px 0",
                                    backgroundColor: ConstantStyle.secondary,
                                    color: ConstantStyle["white"],
                                    margin: { xs: '0 5px', sm: '0 5px', md: '0 5px', xl: '0 5px' },
                                    fontSize: "16px",
                                    fontWeight: "normal",
                                    "&:hover": {
                                        backgroundColor: ConstantStyle.secondary
                                    }
                                }}
                            >
                                Login/Register
                            </Button>
                        </div>
                    }
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, color: ConstantStyle["grey"], backgroundColor: ConstantStyle["white"] },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );

}

