import {
    Autocomplete,
    TextField
} from "@mui/material";
import PropTypes from "prop-types";
import ConstantStyles from "../../styles/ConstantStyles.json";

function CustomAutocompleteSelect(props) {

    return (
        <Autocomplete
            disablePortal
            id={`customautocompleteselect-${props.id}`}
            options={!props.multiple ? props.options : props.options.filter(opt => props.value.find(val => val.value === opt.value) === undefined)}
            sx={{
                backgroundColor: `${props.disabled ? ConstantStyles["light-grey"] : ConstantStyles.white} !important`,
                borderRadius: ConstantStyles["default-border-radius"],
                width: props.width,
                margin: "0px",
                display: "inline-flex",
                "& .MuiOutlinedInput-root": {
                    color: ConstantStyles["default-text-color"],
                    padding: "12%",
                    paddingRight: "12%",
                    "& .MuiOutlinedInput-input": {
                        padding: "7%"
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderWidth: "1px",
                        borderColor: ConstantStyles["line-border-grey"],
                        marginTop: "5px"
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderWidth: "1px",
                        borderColor: ConstantStyles["line-border-grey"]
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderWidth: "1px",
                        borderColor: ConstantStyles.primary
                    },
                    "& .MuiOutlinedInput-notchedOutline legend": {
                        display: "none"
                    },
                    "&.Mui-disabled": {
                        backgroundColor: `${ConstantStyles["light-grey"]} !important`
                    }
                },
                "& .MuiAutocomplete-tag": {
                    height: "26px"
                },
                "& .MuiAutocomplete-clearIndicator": {
                    display: `none !important`
                },
                ...props.customSX
            }}

            // .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator
            renderInput={(params) =>
                <TextField
                    {...params}
                    placeholder={props.placeholder}
                    variant="outlined"
                />
            }
            value={props.value}
            onChange={(event, newValue) => {
                if (props.multiple && newValue.find(val => val.value === "all") !== undefined) {
                    props.onChange(props.options.filter(opt => opt.value !== "all"), ...props.additionalParameters);
                }
                else {
                    props.onChange(newValue, ...props.additionalParameters);
                }
            }}
            fullWidth={props.fullWidth}
            disabled={props.disabled}
            multiple={props.multiple}
            filterSelectedOptions={props.multiple}
            disableCloseOnSelect={props.disableCloseOnSelect}
            limitTags={3}
        />
    )
}

CustomAutocompleteSelect.propTypes = {
    id: PropTypes.string,
    customSX: PropTypes.object,
    options: PropTypes.array,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    width: PropTypes.string,
    multiple: PropTypes.bool,
    disableCloseOnSelect: PropTypes.bool,
    additionalParameters: PropTypes.array
};

CustomAutocompleteSelect.defaultProps = {
    id: "",
    customSX: {},
    options: [],
    value: "",
    placeholder: "Select an option...",
    onChange: () => { },
    fullWidth: true,
    disabled: false,
    width: "100%",
    multiple: false,
    disableCloseOnSelect: false,
    additionalParameters: []
};

export default CustomAutocompleteSelect;

// SAMPLE
// value: if single -> null or { value: "", label: "" }, if multiple -> [] or [{ value: "", label: ""}]
// options: [{ value: "", label: ""}, { value: "", label: ""}, ...]