import React from 'react';
import { useState, useEffect, useMemo } from "react";
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../../utils/GlobalStates';
import ConstantStyles from "../../../../styles/ConstantStyles.json";
import Fade from '@mui/material/Fade';
import Odd_EvenBet from '../../../../utils/Odd_EvenBet.json';
import Even_EvenBet from '../../../../utils/Even_EvenBet.json';
import Odd_OddBet from '../../../../utils/Odd_OddBet.json';
import Even_OddBet from '../../../../utils/Even_OddBet.json';
import RangeBet from '../../../../utils/RangeBet.json';
import RangeBet1 from '../../../../utils/RangeBet1.json';
import RangeBet2 from '../../../../utils/RangeBet2.json';
import RangeBet3 from '../../../../utils/RangeBet3.json';
import RangeBet4 from '../../../../utils/RangeBet4.json';

import CustomAutocompleteSelect from '../../../shared/CustomAutocompleteSelect';
import CustomTextField from '../../../shared/CustomTextField';
import OutlinedInput from "@mui/material/OutlinedInput";



import BetAmount from '../../../../utils/BetAmount.json';
import {
    Grid,
    Card
} from '@mui/material';

import ball_blue from '../../../../assets/svg/ball_blue.svg';
import ball_red from '../../../../assets/svg/ball_red.svg';
import ball_redblue from '../../../../assets/svg/ball_redblue.svg';

import btn_oddeven from '../../../../assets/svg/btn_oddeven.svg';
import btn_eveneven from '../../../../assets/svg/btn_eveneven.svg';
import btn_oddodd from '../../../../assets/svg/btn_oddodd.svg';
import btn_evenodd from '../../../../assets/svg/btn_evenodd.svg';

import btn_1_19_19_19 from '../../../../assets/svg/btn_1-19_19-19.svg';
import btn_1_19_20_38 from '../../../../assets/svg/btn_1-19_20-38.svg';
import btn_20_38_20_38 from '../../../../assets/svg/btn_20_38_20-38.svg';
import btn_20_38_1_19 from '../../../../assets/svg/btn_20-38_1-19.svg';


import payout_3_50 from '../../../../assets/svg/payout-3.50.svg';
import payout_16_80 from '../../../../assets/svg/payout-16.80.svg';
import payout_640 from '../../../../assets/svg/payout-640.svg';
import payout_1280 from '../../../../assets/svg/payout-1280.svg';

const LotteryForms = (props) => {
    const globalStates = useHookState(GlobalStates);
    const [fade, setFade] = useState(props.checked);
    const [firstNoRed, setFirstNoRed] = useState([]);
    const [firstNoBlue, setFirstNoBlue] = useState([]);
    const [secondNoRed, setSecondNoRed] = useState([]);
    const [secondNoBlue, setSecondNoBlue] = useState([]);
    const [secondNo, setSecondNo] = useState([]);
    const [selectedFirstRed, setSelectedFirstRed] = useState([]);
    const [selectedFirstBlue, setSelectedFirstBlue] = useState([]);
    const [selectedSecondRed, setSelectedSecondRed] = useState([]);
    const [selectedSecondBlue, setSelectedSecondBlue] = useState([]);
    const [numberChecker1, setNumberChecker1] = useState(true);
    const [selectedSecond, setSelectedSecond] = useState([]);
    const [clear, setClear] = useState(true);


    const [rangeBet, setRangeBet] = useState("");
    const [rangeIndex, setRangeIndex] = useState("");
    const [oddEvenIndex, setOddEvenIndex] = useState("");

    const [evenEven, setEvenEvenIndex] = useState("");



    const [oddEvenBet, setOddEvenBet] = useState("");
    const [evenEvenBet, setEvenEvenBet] = useState("");
    const [oddOddBet, setOddOddBet] = useState("");
    const [oddOddBet1, setOddOddBet1] = useState("");


    const [oddEvenData, setOddEventData] = useState(Odd_EvenBet);
    const [evenEvenData, setEvenEvenData] = useState(Even_EvenBet);
    const [oddOddData, setOddOddData] = useState(Odd_OddBet);
    const [oddOddData1, setOddOddData1] = useState(Even_OddBet);




    const [rangeBetData, setRangeBetData] = useState(RangeBet);
    const [open, setOpen] = useState(false);
    const [straigthBetAmt, setStraightBetAmt] = useState({ label: "0", value: 0 });
    const [singleBallBet, setSingleBallBet] = useState({ label: "0", value: 0 });
    const [rumbleBetAmt, setBetAmt] = useState({ label: "0", value: 0 });

    const [side1Amt, setSide1Amt] = useState(50);

    const [oddEvenAmt, setOddEvenAmt] = useState({ label: "50", value: 50 });
    const [evenEventAmt, setEvenEvenAmt] = useState({ label: "50", value: 50 });
    const [oddOddAmt, setOddOddAmt] = useState({ label: "50", value: 50 });
    const [oddOddAmt1, setOddOddAmt1] = useState({ label: "50", value: 50 });

    const [side2Amt, setSide2Amt] = useState(50);
    const [rangebetAmt1, setRangebetAmt1] = useState({ label: "50", value: 50 });
    const [rangebetAmt2, setRangebetAmt2] = useState({ label: "50", value: 50 });
    const [rangebetAmt3, setRangebetAmt3] = useState({ label: "50", value: 50 });
    const [rangebetAmt4, setRangebetAmt4] = useState({ label: "50", value: 50 });


    const [rangebet1, setRangebet1] = useState("");
    const [rangebet2, setRangebet2] = useState("");
    const [rangebet3, setRangebet3] = useState("");
    const [rangebet4, setRangebet4] = useState("");

    let num = ["-"];
    let num2 = ["-"];
    let count = 39;
    let count2 = 39,
        oddEvenBetStyle = "",
        amount = 0;
    useEffect(() => {
        let newAmount = 0,
            straight = selectedFirstRed.length > 0 && selectedFirstBlue.length > 0 ? parseFloat(straigthBetAmt?.value) : 0,
            rumble = selectedSecondRed.length > 0 && selectedSecondBlue.length > 0 ? rumbleBetAmt?.value : 0,
            singleBet_amt = selectedSecond.length > 0 ? singleBallBet?.value : 0,
            oddEvenBet_amt = oddEvenBet !== "" ? oddEvenAmt?.value : 0,
            evenEvenBet_amt = evenEvenBet !== "" ? evenEventAmt?.value : 0,
            oddOddBet_amt = oddOddBet !== "" ? oddOddAmt?.value : 0,
            oddOddBet_amt1 = oddOddBet1 !== "" ? oddOddAmt1?.value : 0,
            rangebet_amt1 = rangebet1 !== "" ? rangebetAmt1?.value : 0,
            rangebet_amt2 = rangebet2 !== "" ? rangebetAmt2?.value : 0,
            rangebet_amt3 = rangebet3 !== "" ? rangebetAmt3?.value : 0,
            rangebet_amt4 = rangebet4 !== "" ? rangebetAmt4?.value : 0;
        newAmount = straight + rumble + singleBet_amt + oddEvenBet_amt + evenEvenBet_amt + oddOddBet_amt + oddOddBet_amt1 + rangebet_amt1 + rangebet_amt2 + rangebet_amt3 + rangebet_amt4;
        let payloads = {
            cardNo: props.index,
            amount: newAmount,
            firstNoRed: selectedFirstRed,
            firstNoBlue: selectedFirstBlue,
            straigthBetAmt: straigthBetAmt?.value,
            rumbleBetAmt: rumbleBetAmt?.value,
            singleBallBet: singleBallBet?.value,
            secondNoRed: selectedSecondRed,
            secondNoBlue: selectedSecondBlue,
            singleBet: selectedSecond,
            oddEvenBet: oddEvenBet,
            rangeBet: rangeBet,

            rangebet1: rangebet1,
            rangebet2: rangebet2,
            rangebet3: rangebet3,
            rangebet4: rangebet4,
            rangebetAmt1: rangebetAmt1?.value ?? 0,
            rangebetAmt2: rangebetAmt2?.value ?? 0,
            rangebetAmt3: rangebetAmt3?.value ?? 0,
            rangebetAmt4: rangebetAmt4?.value ?? 0,

            oddEven1: oddEvenBet,
            oddEven2: evenEvenBet,
            oddEven3: oddOddBet,
            oddEven4: oddOddBet1,
            oddEvenAmt1: oddEvenAmt?.value ?? 0,
            oddEvenAmt2: evenEventAmt?.value ?? 0,
            oddEvenAmt3: oddOddAmt?.value ?? 0,
            oddEvenAmt4: oddOddAmt1?.value ?? 0,




        }
        props.func(payloads);

    }, [props.index,
    selectedFirstRed.length,
    selectedFirstBlue.length,
    selectedSecondRed.length,
    selectedSecondBlue.length,
        oddEvenBet,
        evenEvenBet,
        oddOddBet,
        oddOddBet1,
        rangeBet,
    selectedSecond.length,
    props.newCount,
        straigthBetAmt?.value,
        rumbleBetAmt,
        singleBallBet,
        rangebet1,
        rangebet2,
        rangebet3,
        rangebet4
    ]);


    useEffect(() => {
        setFade(true)
    }, [props.checked]);

    useEffect(() => {
        if (!props.isLoading) {
            fetchRandom()
        }
    }, [props?.quickPlay]);


    useEffect(() => {
        setFade(false)
        setFirstNoRed([])
        setFirstNoBlue([])
        setSecondNoRed([])
        setSecondNoBlue([])
        setSecondNo([])
        setSelectedFirstRed([])
        setSelectedFirstBlue([])
        setSelectedSecondRed([])
        setSelectedSecondBlue([])
        setSelectedSecond([])
        setOddEvenBet("")
        setRangeBet("")
        setRangeIndex("")
        setOddEvenIndex("")

        setEvenEvenBet("")
        setEvenEvenAmt({ label: 0, value: 0 })

        setOddEvenBet("")
        setOddEvenAmt({ label: 0, value: 0 })

        setOddOddBet("")
        setOddOddAmt({ label: 0, value: 0 })

        setOddOddBet1("")
        setOddOddAmt1({ label: 0, value: 0 })
        setRangebet1("")
        setRangebetAmt1({ label: 0, value: 0 })
        setRangebet2("")
        setRangebetAmt2({ label: 0, value: 0 })
        setRangebet3("")
        setRangebetAmt3({ label: 0, value: 0 })
        setRangebet4("")
        setRangebetAmt4({ label: 0, value: 0 })


        setTimeout(() => {
            setOddEventData(Odd_EvenBet)
            setEvenEvenData(Even_EvenBet)
            setRangeBetData(RangeBet)
            setOddOddData(Odd_OddBet)
            setOddOddData1(Even_OddBet)
        }, 10)
    }, [props.clearTicket]);

    useEffect(() => {
        setSelectedFirstRed(firstNoRed)
        setSelectedFirstBlue(firstNoBlue)
        setSelectedSecondRed(secondNoRed)
        setSelectedSecondBlue(secondNoBlue)
        setSelectedSecond(secondNo)
    }, [numberChecker1]);



    const fetchRandom = (singleRandom) => {
        let quickPlayRandomsRed = [],
            quickPlayRandomsBlue = [],
            quickPlayRandomsRed1 = [],
            quickPlayRandomsBlue1 = [],
            quickPlayRandomsPower = [],
            new_index = props.index;

        if ((props.quickPlay && props.quickPower) && (singleRandom === undefined)) {
            quickPlayRandomsRed = JSON.parse(props?.quickPlay)
            quickPlayRandomsBlue = JSON.parse(props?.quickPlay) // props pala to
            quickPlayRandomsPower = JSON.parse(props?.quickPower)

            var refreshIntervalId = setInterval(function () {
                let min = 1;
                let max = 38;
                let minPower = 1;
                let maxPower = 38;
                let number = 0;
                quickPlayRandomsRed = [];
                quickPlayRandomsBlue = [];
                quickPlayRandomsPower = [];
                quickPlayRandomsRed1 = [];
                quickPlayRandomsBlue1 = [];
                for (let i = 0; i < count; i++) {
                    let numbers = [];
                    for (let j = 0; j < 1; j++) {
                        numbers.push(
                            Math.round(Math.random() * (max - min) + min)
                        );
                    }
                    quickPlayRandomsRed.push({ numbers })
                }

                for (let i = 0; i < count; i++) {
                    let numbers = [];
                    for (let j = 0; j < 1; j++) {
                        numbers.push(
                            Math.round(Math.random() * (max - min) + min)
                        );
                    }
                    quickPlayRandomsBlue.push({ numbers })
                }

                for (let i = 0; i < count; i++) {
                    let numbersMax = [];
                    numbersMax.push(
                        Math.round(Math.random() * (maxPower - minPower) + minPower)
                    );
                    quickPlayRandomsPower.push({ numbersMax })
                }

                for (let i = 0; i < count; i++) {
                    let numbers = [];
                    for (let j = 0; j < 1; j++) {
                        numbers.push(
                            Math.round(Math.random() * (max - min) + min)
                        );
                    }
                    quickPlayRandomsRed1.push({ numbers })
                }

                for (let i = 0; i < count; i++) {
                    let numbers = [];
                    for (let j = 0; j < 1; j++) {
                        numbers.push(
                            Math.round(Math.random() * (max - min) + min)
                        );
                    }
                    quickPlayRandomsBlue1.push({ numbers })
                }

                let mineven = 1;
                let maxeven = 4;
                quickPlayRandomsPower = [];
                for (let i = 0; i < count; i++) {
                    let numbersMax = [];
                    numbersMax.push(
                        Math.round(Math.random() * (maxeven - mineven) + mineven)
                    );
                    quickPlayRandomsPower.push({ numbersMax })
                }
                number = quickPlayRandomsPower[new_index]?.numbersMax;
                if (number[0] === 1) {
                    setOddOddBet1("ODD-EVEN")
                    setOddOddAmt1({ label: side1Amt, value: side1Amt })


                    setOddOddBet("")
                    setOddOddAmt({ label: side1Amt, value: side1Amt })

                    setOddEvenBet("")
                    setOddEvenAmt({ label: side1Amt, value: side1Amt })

                    setEvenEvenBet("")
                    setEvenEvenAmt({ label: side1Amt, value: side1Amt })

                }
                else if (number[0] === 2) {
                    setOddOddBet("ODD-ODD")
                    setOddOddAmt({ label: side1Amt, value: side1Amt })

                    setOddOddBet1("")
                    setOddOddAmt1({ label: side1Amt, value: side1Amt })

                    setOddEvenBet("")
                    setOddEvenAmt({ label: side1Amt, value: side1Amt })

                    setEvenEvenBet("")
                    setEvenEvenAmt({ label: side1Amt, value: side1Amt })

                }
                else if (number[0] === 3) {
                    setOddEvenBet("ODD-EVEN")
                    setOddEvenAmt({ label: side1Amt, value: side1Amt })

                    setOddOddBet("")
                    setOddOddAmt({ label: side1Amt, value: side1Amt })

                    setOddOddBet1("")
                    setOddOddAmt1({ label: side1Amt, value: side1Amt })

                    setEvenEvenBet("")
                    setEvenEvenAmt({ label: side1Amt, value: side1Amt })
                }
                else if (number[0] === 4) {
                    setEvenEvenBet("EVEN-EVEN")
                    setEvenEvenAmt({ label: side1Amt, value: side1Amt })

                    setOddEvenBet("")
                    setOddEvenAmt({ label: side1Amt, value: side1Amt })

                    setOddOddBet("")
                    setOddOddAmt({ label: side1Amt, value: side1Amt })

                    setOddOddBet1("")
                    setOddOddAmt1({ label: side1Amt, value: side1Amt })
                }
                let minr = 1;
                let maxr = 4;
                quickPlayRandomsPower = [];
                for (let i = 0; i < count; i++) {
                    let numbersMax = [];
                    numbersMax.push(
                        Math.round(Math.random() * (maxr - minr) + minr)
                    );
                    quickPlayRandomsPower.push({ numbersMax })
                }
                number = quickPlayRandomsPower[new_index]?.numbersMax;
                if (number[0] === 1) {
                    setRangebet1("1-19 | 1-19")
                    setRangebetAmt1({ label: side2Amt, value: side2Amt })


                    setRangebet2("")
                    setRangebetAmt2({ label: side2Amt, value: side2Amt })

                    setRangebet3("")
                    setRangebetAmt3({ label: side2Amt, value: side2Amt })

                    setRangebet4("")
                    setRangebetAmt4({ label: side2Amt, value: side2Amt })

                }
                else if (number[0] === 2) {
                    setRangebet2("1-19 | 20-38")
                    setRangebetAmt2({ label: side2Amt, value: side2Amt })


                    setRangebet1("")
                    setRangebetAmt1({ label: side2Amt, value: side2Amt })

                    setRangebet3("")
                    setRangebetAmt3({ label: side2Amt, value: side2Amt })

                    setRangebet4("")
                    setRangebetAmt4({ label: side2Amt, value: side2Amt })

                }
                else if (number[0] === 3) {
                    setRangebet3("20-38 | 20-38")
                    setRangebetAmt3({ label: side2Amt, value: side2Amt })


                    setRangebet1("")
                    setRangebetAmt1({ label: side2Amt, value: side2Amt })

                    setRangebet2("")
                    setRangebetAmt2({ label: side2Amt, value: side2Amt })

                    setRangebet4("")
                    setRangebetAmt4({ label: side2Amt, value: side2Amt })
                }
                else if (number[0] === 4) {
                    setRangebet4("20-38 | 1-19")
                    setRangebetAmt4({ label: side2Amt, value: side2Amt })


                    setRangebet1("")
                    setRangebetAmt1({ label: side2Amt, value: side2Amt })

                    setRangebet2("")
                    setRangebetAmt2({ label: side2Amt, value: side2Amt })

                    setRangebet3("")
                    setRangebetAmt3({ label: side2Amt, value: side2Amt })
                }
                setFirstNoRed(quickPlayRandomsRed[new_index]?.numbers)
                setFirstNoBlue(quickPlayRandomsBlue[new_index]?.numbers)
                setSelectedFirstRed(quickPlayRandomsRed[new_index]?.numbers)
                setSelectedFirstBlue(quickPlayRandomsBlue[new_index]?.numbers)
                setSecondNo(quickPlayRandomsPower[new_index]?.numbersMax)
                setSelectedSecond(quickPlayRandomsPower[new_index]?.numbersMax)

                setSelectedSecondRed(quickPlayRandomsRed1[new_index]?.numbers)
                setSelectedSecondBlue(quickPlayRandomsBlue1[new_index]?.numbers)
                setSecondNoRed(quickPlayRandomsRed1[new_index]?.numbers)
                setSecondNoBlue(quickPlayRandomsBlue1[new_index]?.numbers)

            }, 5);


            setTimeout(() => {
                clearInterval(refreshIntervalId);
            }, 1000)
        }
        else if (singleRandom === "singleRandom") {
            quickPlayRandomsRed = ["-"];
            quickPlayRandomsPower = ["-"];

            var refreshIntervalId = setInterval(function () {
                let min = 1;
                let max = 38;
                let minPower = 1;
                let maxPower = 38;
                quickPlayRandomsRed = [];
                quickPlayRandomsBlue = [];
                quickPlayRandomsPower = [];
                for (let i = 0; i < count; i++) {
                    let numbers = [];
                    for (let j = 0; j < 1; j++) {
                        numbers.push(
                            Math.round(Math.random() * (max - min) + min)
                        );
                    }
                    quickPlayRandomsRed.push({ numbers })
                }

                for (let i = 0; i < count; i++) {
                    let numbers = [];
                    for (let j = 0; j < 1; j++) {
                        numbers.push(
                            Math.round(Math.random() * (max - min) + min)
                        );
                    }
                    quickPlayRandomsBlue.push({ numbers })
                }

                for (let i = 0; i < count; i++) {
                    let numbersMax = [];
                    numbersMax.push(
                        Math.round(Math.random() * (maxPower - minPower) + minPower)
                    );
                    quickPlayRandomsPower.push({ numbersMax })
                }


                setFirstNoRed(quickPlayRandomsRed[new_index]?.numbers)
                setFirstNoBlue(quickPlayRandomsBlue[new_index]?.numbers)
                setSelectedFirstRed(quickPlayRandomsRed[new_index]?.numbers)
                setSelectedFirstBlue(quickPlayRandomsBlue[new_index]?.numbers)
            }, 50);


            setTimeout(() => {
                clearInterval(refreshIntervalId);
            }, 1000)
        }
        else if (singleRandom === "SingleBallBet") {
            quickPlayRandomsPower = ["-"];

            var refreshIntervalId = setInterval(function () {
                let minPower = 1;
                let maxPower = 38;
                quickPlayRandomsPower = [];
                for (let i = 0; i < count; i++) {
                    let numbersMax = [];
                    numbersMax.push(
                        Math.round(Math.random() * (maxPower - minPower) + minPower)
                    );
                    quickPlayRandomsPower.push({ numbersMax })
                }
                setSecondNo(quickPlayRandomsPower[new_index]?.numbersMax)
                setSelectedSecond(quickPlayRandomsPower[new_index]?.numbersMax)
            }, 50);


            setTimeout(() => {

                clearInterval(refreshIntervalId);
            }, 1000)



        }
        else if (singleRandom === "singleRandom2") {

            var refreshIntervalId = setInterval(function () {
                let min = 1;
                let max = 38;
                quickPlayRandomsRed1 = [];
                quickPlayRandomsBlue1 = [];
                for (let i = 0; i < count; i++) {
                    let numbers = [];
                    for (let j = 0; j < 1; j++) {
                        numbers.push(
                            Math.round(Math.random() * (max - min) + min)
                        );
                    }
                    quickPlayRandomsRed1.push({ numbers })
                }

                for (let i = 0; i < count; i++) {
                    let numbers = [];
                    for (let j = 0; j < 1; j++) {
                        numbers.push(
                            Math.round(Math.random() * (max - min) + min)
                        );
                    }
                    quickPlayRandomsBlue1.push({ numbers })
                }


                setSelectedSecondRed(quickPlayRandomsRed1[new_index]?.numbers)
                setSelectedSecondBlue(quickPlayRandomsBlue1[new_index]?.numbers)
                setSecondNoRed(quickPlayRandomsRed1[new_index]?.numbers)
                setSecondNoBlue(quickPlayRandomsBlue1[new_index]?.numbers)
            }, 50);


            setTimeout(() => {
                clearInterval(refreshIntervalId);
            }, 1000)
        }
        else if (singleRandom === "oddevenRandom") {

            let number = 0;
            var refreshIntervalId = setInterval(function () {
                let min = 1;
                let max = 4;
                quickPlayRandomsPower = [];
                for (let i = 0; i < count; i++) {
                    let numbersMax = [];
                    numbersMax.push(
                        Math.round(Math.random() * (max - min) + min)
                    );
                    quickPlayRandomsPower.push({ numbersMax })
                }
                number = quickPlayRandomsPower[new_index]?.numbersMax;
                if (number[0] === 1) {
                    setOddOddBet1("ODD-EVEN")
                    setOddOddAmt1({ label: side1Amt, value: side1Amt })


                    setOddOddBet("")
                    setOddOddAmt({ label: side1Amt, value: side1Amt })

                    setOddEvenBet("")
                    setOddEvenAmt({ label: side1Amt, value: side1Amt })

                    setEvenEvenBet("")
                    setEvenEvenAmt({ label: side1Amt, value: side1Amt })

                }
                else if (number[0] === 2) {
                    setOddOddBet("ODD-ODD")
                    setOddOddAmt({ label: side1Amt, value: side1Amt })

                    setOddOddBet1("")
                    setOddOddAmt1({ label: side1Amt, value: side1Amt })

                    setOddEvenBet("")
                    setOddEvenAmt({ label: side1Amt, value: side1Amt })

                    setEvenEvenBet("")
                    setEvenEvenAmt({ label: side1Amt, value: side1Amt })

                }
                else if (number[0] === 3) {
                    setOddEvenBet("ODD-EVEN")
                    setOddEvenAmt({ label: side1Amt, value: side1Amt })

                    setOddOddBet("")
                    setOddOddAmt({ label: side1Amt, value: side1Amt })

                    setOddOddBet1("")
                    setOddOddAmt1({ label: side1Amt, value: side1Amt })

                    setEvenEvenBet("")
                    setEvenEvenAmt({ label: side1Amt, value: side1Amt })
                }
                else if (number[0] === 4) {
                    setEvenEvenBet("EVEN-EVEN")
                    setEvenEvenAmt({ label: side1Amt, value: side1Amt })

                    setOddEvenBet("")
                    setOddEvenAmt({ label: side1Amt, value: side1Amt })

                    setOddOddBet("")
                    setOddOddAmt({ label: side1Amt, value: side1Amt })

                    setOddOddBet1("")
                    setOddOddAmt1({ label: side1Amt, value: side1Amt })
                }
            }, 10);

            setTimeout(() => {

                clearInterval(refreshIntervalId);
            }, 1000)
        }
        else if (singleRandom === "randomRange") {

            let number = 0;
            var refreshIntervalId = setInterval(function () {
                let minr = 1;
                let maxr = 4;
                quickPlayRandomsPower = [];
                for (let i = 0; i < count; i++) {
                    let numbersMax = [];
                    numbersMax.push(
                        Math.round(Math.random() * (maxr - minr) + minr)
                    );
                    quickPlayRandomsPower.push({ numbersMax })
                }
                number = quickPlayRandomsPower[new_index]?.numbersMax;
                if (number[0] === 1) {
                    setRangebet1("1-19 | 1-19")
                    setRangebetAmt1({ label: side2Amt, value: side2Amt })


                    setRangebet2("")
                    setRangebetAmt2({ label: side2Amt, value: side2Amt })

                    setRangebet3("")
                    setRangebetAmt3({ label: side2Amt, value: side2Amt })

                    setRangebet4("")
                    setRangebetAmt4({ label: side2Amt, value: side2Amt })

                }
                else if (number[0] === 2) {
                    setRangebet2("1-19 | 20-38")
                    setRangebetAmt2({ label: side2Amt, value: side2Amt })


                    setRangebet1("")
                    setRangebetAmt1({ label: side2Amt, value: side2Amt })

                    setRangebet3("")
                    setRangebetAmt3({ label: side2Amt, value: side2Amt })

                    setRangebet4("")
                    setRangebetAmt4({ label: side2Amt, value: side2Amt })

                }
                else if (number[0] === 3) {
                    setRangebet3("20-38 | 20-38")
                    setRangebetAmt3({ label: side2Amt, value: side2Amt })


                    setRangebet1("")
                    setRangebetAmt1({ label: side2Amt, value: side2Amt })

                    setRangebet2("")
                    setRangebetAmt2({ label: side2Amt, value: side2Amt })

                    setRangebet4("")
                    setRangebetAmt4({ label: side2Amt, value: side2Amt })
                }
                else if (number[0] === 4) {
                    setRangebet4("20-38 | 1-19")
                    setRangebetAmt4({ label: side2Amt, value: side2Amt })


                    setRangebet1("")
                    setRangebetAmt1({ label: side2Amt, value: side2Amt })

                    setRangebet2("")
                    setRangebetAmt2({ label: side2Amt, value: side2Amt })

                    setRangebet3("")
                    setRangebetAmt3({ label: side2Amt, value: side2Amt })
                }
            }, 10);

            setTimeout(() => {
                clearInterval(refreshIntervalId);
            }, 1000)
        }
    }


    const handleClearTickets = (singleRandom) => {
        if (singleRandom === "singleRandom") {
            setFirstNoRed([])
            setSelectedFirstRed([])
            setFirstNoBlue([])
            setSelectedFirstBlue([])
        }

        else if (singleRandom === "SingleBallBet") {
            setSecondNo([])
            setSelectedSecond([])
        }
        else if (singleRandom === "singleRandom2") {
            setSelectedSecondRed([])
            setSelectedSecondBlue([])
            setSecondNoRed([])
            setSecondNoBlue([])
        }
        else if (singleRandom === "oddevenRandom") {

            setEvenEvenBet("")
            setEvenEvenAmt({ label: 0, value: 0 })

            setOddEvenBet("")
            setOddEvenAmt({ label: 0, value: 0 })

            setOddOddBet("")
            setOddOddAmt({ label: 0, value: 0 })

            setOddOddBet1("")
            setOddOddAmt1({ label: 0, value: 0 })
        }
        else if (singleRandom === "randomRange") {

            setRangebet1("")
            setRangebetAmt1({ label: 0, value: 0 })

            setRangebet2("")
            setRangebetAmt2({ label: 0, value: 0 })

            setRangebet3("")
            setRangebetAmt3({ label: 0, value: 0 })

            setRangebet4("")
            setRangebetAmt4({ label: 0, value: 0 })
        }
        setClear(!clear)
        // setTimeout(() => {
        //     setOddEventData(Odd_EvenBet)
        //     setRangeBetData(RangeBet)
        // }, 10)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseClear = () => {
        setOpen(false);
        if (selectedFirstRed.length === 0 || selectedFirstBlue.length === 0) {
            setFirstNoRed([])
            setSelectedFirstRed([])
            setFirstNoBlue([])
            setSelectedFirstBlue([])
            setSecondNo([])
            setSelectedSecond([])
            setOddEvenBet("")
            setRangeBet("")
            setRangeIndex("")
            setOddEvenIndex("")
            setOddEventData([])
            setRangeBetData([])
            setEvenEvenData([])
            setOddOddData([])
            setTimeout(() => {
                setOddEventData(Odd_EvenBet)
                setRangeBetData(RangeBet)
                setEvenEvenData(Even_EvenBet)
                setOddOddData(Odd_OddBet)
            }, 10)
        }
    };

    const renderDraws = useMemo(() => {
        return BetAmount.map((col, index) => {
            return { value: col.value, label: col.label }
        });
    }, []);

    return (
        <Fade in={true} timeout={1000}>
            {globalStates.isMobile.get() ?
                // <>test</>
                // < Grid item xs={12} id={`mobileCard${props.index}`}>

                //     <Grid container spacing={2} p={{ xs: 0, sm: 4 }}

                //         style={{
                //             backgroundColor: ConstantStyles['white'],
                //             border: ConstantStyles['default-border'],
                //             margin: "2%",
                //             width: "96%"
                //         }} >

                //         < Grid item xs={8} p={0} >
                //             {selectedFirstRed.length === 0 && selectedFirstBlue.length === 0 ?
                //                 <p onClick={handleClickOpen} style={{ fontSize: "16px", marginBottom: "15px" }}>+ Add New Line</p>
                //                 :
                //                 <p onClick={handleClickOpen}>
                //                     Main Bet:
                //                     <br />
                //                     {selectedFirstRed.length > 0 ?

                //                         selectedFirstRed.map((first, index) =>
                //                         (
                //                             <div
                //                                 key={index}
                //                                 style={{
                //                                     backgroundColor: ConstantStyles['almost-red'],
                //                                     color: "#fff",
                //                                     fontSize: "14px",
                //                                     padding: "5% 1%",
                //                                     height: "34%",
                //                                     borderRadius: "50%",
                //                                     width: "20%",
                //                                     textAlign: "center",
                //                                     display: "inline-block",
                //                                     marginRight: "5px",
                //                                     fontWeight: ConstantStyles['bold']
                //                                 }}
                //                             >{first}</div>
                //                         )
                //                         )

                //                         :
                //                         ""

                //                     }
                //                     {selectedFirstBlue.length > 0 ?

                //                         selectedFirstBlue.map((first, index) =>
                //                         (
                //                             <div
                //                                 key={index}
                //                                 style={{
                //                                     backgroundColor: ConstantStyles['almost-blue'],
                //                                     color: "#fff",
                //                                     fontSize: "14px",
                //                                     padding: "5% 1%",
                //                                     // height: "36%",
                //                                     borderRadius: "50%",
                //                                     width: "20%",
                //                                     textAlign: "center",
                //                                     display: "inline-block",
                //                                     marginRight: "5px",
                //                                     fontWeight: ConstantStyles['bold']
                //                                 }}
                //                             >{first}</div>
                //                         )
                //                         )

                //                         :
                //                         ""

                //                     }
                //                     <br />
                //                     Single Bet: <b>{selectedSecond}</b>
                //                     <br />
                //                     Odd Even: <b>{oddEvenBet}</b>
                //                     <br />
                //                     Range: <b>{rangeBet}</b>
                //                 </p>
                //             }
                //         </Grid>
                //         < Grid item xs={4} p={0} style={{ paddingTop: "0" }} >
                //             <div style={{ float: "right" }}>

                //                 {selectedFirstRed.length !== 0 && selectedFirstBlue.length !== 0 ?

                //                     <p style={{
                //                         fontSize: "11px",
                //                         cursor: "pointer",
                //                         background: ConstantStyles['almost-red'],
                //                         color: ConstantStyles['white'],
                //                         minWidth: "60px",
                //                         padding: "2%",
                //                         textAlign: "center",
                //                         padding: "4%"
                //                     }}
                //                         onClick={handleClearTickets}
                //                     >Clear</p>
                //                     :

                //                     <p style={{
                //                         fontSize: "11px",
                //                         cursor: "pointer",
                //                         background: ConstantStyles.primary,
                //                         color: ConstantStyles['white'],
                //                         minWidth: "60px",
                //                         padding: "4%"
                //                     }}
                //                         onClick={(e) => {
                //                             fetchRandom("singleRandom")
                //                         }}
                //                     >Lucky Pick</p>
                //                 }
                //             </div>
                //         </Grid>
                //     </Grid>
                //     <Dialog
                //         open={open}
                //         onClose={handleClose}
                //         aria-labelledby="alert-dialog-title"
                //         aria-describedby="alert-dialog-description"
                //         PaperProps={{
                //             sx: {
                //                 margin: "0",
                //                 maxHeight: "unset",
                //                 overflowY: "auto",
                //                 height: "100%",
                //                 padding: "0"
                //             }
                //         }}
                //     >
                //         <DialogTitle id="alert-dialog-title">
                //             <p style={{ float: "left" }} onClick={handleCloseClear}>
                //                 Betting Form
                //             </p>
                //             <p style={{ float: "right" }} onClick={handleCloseClear}>
                //                 <CloseIcon />
                //             </p>
                //         </DialogTitle>
                //         <DialogContent

                //             style={{
                //                 paddingLeft: 0,
                //                 paddingRight: 0,
                //                 paddingTop: 0,
                //                 paddingBottom: 0
                //             }}
                //         >
                //             < Grid item xs={12} sm={4} sx={{ maxWidth: { xs: "100%", sm: "auto" }, padding: "0% 0", minHeight: "473.20px" }}>
                //                 <div style={{ textAlign: "right" }}>
                //                     <small style={{
                //                         fontSize: "13px",
                //                         cursor: "pointer",
                //                         background: ConstantStyles['almost-red'],
                //                         color: ConstantStyles['white'],
                //                         marginRight: "5px",
                //                         padding: "2%"
                //                     }}
                //                         onClick={handleClearTickets}
                //                     >Clear</small>

                //                     <small style={{
                //                         fontSize: "13px",
                //                         cursor: "pointer",
                //                         background: ConstantStyles.primary,
                //                         color: ConstantStyles['white'],
                //                         padding: "2%"
                //                     }}
                //                         onClick={(e) => {
                //                             fetchRandom("singleRandom")
                //                         }}
                //                     >Lucky Pick</small>
                //                 </div>
                //                 <Card
                //                     sx={{
                //                         maxWidth: "100%",
                //                         backgroundColor: ConstantStyles.white,
                //                         color: ConstantStyles['dark-grey']
                //                     }}
                //                 >
                //                     <p style={{
                //                         fontSize: "13px",
                //                         textAlign: "left",
                //                         width: "100%",
                //                         padding: "5% 0 0 5%",
                //                         fontWeight: ConstantStyles['bold']
                //                     }}>Main Bet</p>
                //                     <span style={{
                //                         fontSize: "13px",
                //                         textAlign: "left",
                //                         width: "100%",
                //                         padding: "0% 0 1% 5%"
                //                     }}> Pick 1 number in Red and number in Blue</span>
                //                     <p style={{
                //                         fontSize: "12px",
                //                         textAlign: "center",
                //                         width: "100%",
                //                         paddingTop: "2px",
                //                         padding: "0% 0 1% 0%"
                //                     }}>
                //                         {selectedFirstRed.length > 0 ?

                //                             selectedFirstRed.map((first, index) =>
                //                             (
                //                                 <div
                //                                     key={index}
                //                                     style={{
                //                                         backgroundColor: ConstantStyles['almost-red'],
                //                                         color: "#fff",
                //                                         fontSize: "14px",
                //                                         padding: "2% 1%",
                //                                         height: "34%",
                //                                         borderRadius: "50%",
                //                                         width: "10%",
                //                                         display: "inline-block",
                //                                         marginRight: "5px"
                //                                     }}
                //                                 >{first}</div>
                //                             )
                //                             )

                //                             :
                //                             num.map((first, index) =>
                //                             (
                //                                 <div
                //                                     key={index}
                //                                     style={{
                //                                         backgroundColor: ConstantStyles['almost-red'],
                //                                         color: "#fff",
                //                                         fontSize: "13px",
                //                                         padding: "3% 1%",
                //                                         height: "34%",
                //                                         borderRadius: "50%",
                //                                         width: "10%",
                //                                         display: "inline-block",
                //                                         marginRight: "5px"
                //                                     }}
                //                                 >{first}</div>
                //                             )
                //                             )

                //                         }
                //                         {selectedFirstBlue.length > 0 ?

                //                             selectedFirstBlue.map((first, index) =>
                //                             (
                //                                 <div
                //                                     key={index}
                //                                     style={{
                //                                         backgroundColor: ConstantStyles['almost-blue'],
                //                                         color: "#fff",
                //                                         fontSize: "14px",
                //                                         padding: "2% 1%",
                //                                         // height: "36%",
                //                                         borderRadius: "50%",
                //                                         width: "10%",
                //                                         display: "inline-block",
                //                                         marginRight: "5px"
                //                                     }}
                //                                 >{first}</div>
                //                             )
                //                             )

                //                             :
                //                             num.map((first, index) =>
                //                             (
                //                                 <div
                //                                     key={index}
                //                                     style={{
                //                                         backgroundColor: ConstantStyles['almost-blue'],
                //                                         color: "#fff",
                //                                         fontSize: "13px",
                //                                         padding: "3% 1%",
                //                                         height: "34%",
                //                                         borderRadius: "50%",
                //                                         width: "10%",
                //                                         display: "inline-block",
                //                                         marginRight: "5px"
                //                                     }}
                //                                 >{first}</div>
                //                             )
                //                             )

                //                         }
                //                     </p>

                //                     <Grid
                //                         container
                //                         id={`number_${props.index}`}
                //                         justifyContent="center"
                //                         style={{
                //                             textAlign: "center",
                //                             padding: "1%",
                //                             width: "45%",
                //                             marginLeft: "5%",
                //                             float: "left"
                //                         }} >

                //                         {(() => {
                //                             const arr = [];
                //                             for (let i = 1; i < count; i++) {

                //                                 let new_color = firstNoRed.includes(i) ? "selected_number" : "";
                //                                 arr.push(
                //                                     <Grid item xs={2} sm={2.5}
                //                                         id={`props_red${props.index}number_${i}`}
                //                                         key={`key_${i}`}
                //                                         className={`clear_${i} ${new_color}`}
                //                                         sx={{
                //                                             border: ConstantStyles["black-border"],
                //                                             cursor: "pointer",
                //                                             fontSize: "13px",
                //                                             margin: "2px",
                //                                             backgroundColor: ConstantStyles['almost-red'],
                //                                             color: ConstantStyles['white']
                //                                         }}
                //                                         onClick={(e) => {
                //                                             let numbers = firstNoRed;

                //                                             if (numbers.includes(i)) {
                //                                                 numbers = numbers.filter(e => e !== i)
                //                                                 document.getElementById("props_red" + props.index + "number_" + i).classList.add('not_selected');
                //                                                 document.getElementById("props_red" + props.index + "number_" + i).classList.remove("selected_number");

                //                                             }
                //                                             else if (numbers.length === 0) {

                //                                                 numbers.push(i)
                //                                                 document.getElementById("props_red" + props.index + "number_" + i).classList.add('selected_number');
                //                                                 document.getElementById("props_red" + props.index + "number_" + i).classList.remove("not_selected");
                //                                                 // setFirstNumber("props_" + props.index + "number_" + i)

                //                                             }
                //                                             else {
                //                                                 if (numbers.length < 1) {
                //                                                     // alert("selected_number" + i)
                //                                                     numbers.push(i)
                //                                                     document.getElementById("props_red" + props.index + "number_" + i).classList.add('selected_number');
                //                                                     document.getElementById("props_red" + props.index + "number_" + i).classList.remove("not_selected");
                //                                                 }
                //                                             }

                //                                             setFirstNoRed(numbers)
                //                                             // console.log(numbers)
                //                                             let payloads = {
                //                                                 index: props.index,

                //                                             };

                //                                             setNumberChecker1(!numberChecker1)
                //                                         }
                //                                         }
                //                                     >
                //                                         {i}
                //                                     </Grid>
                //                                 );
                //                             }
                //                             return arr;
                //                         })()}
                //                     </Grid>

                //                     <Grid
                //                         container
                //                         id={`number_${props.index}`}
                //                         justifyContent="center"
                //                         style={{
                //                             textAlign: "center",
                //                             padding: "1%",
                //                             width: "45%",
                //                             marginLeft: "5%",
                //                         }} >

                //                         {(() => {
                //                             const arr = [];
                //                             for (let i = 1; i < count; i++) {

                //                                 let new_color = firstNoBlue.includes(i) ? "selected_number" : "";
                //                                 arr.push(
                //                                     <Grid item xs={2} sm={2.5}
                //                                         id={`props_blue${props.index}number_${i}`}
                //                                         key={`key_${i}`}
                //                                         className={`clear_${i} ${new_color}`}
                //                                         sx={{
                //                                             border: ConstantStyles["black-border"],
                //                                             cursor: "pointer",
                //                                             fontSize: "13px",
                //                                             margin: "2px",
                //                                             backgroundColor: ConstantStyles['almost-blue'],
                //                                             color: ConstantStyles['white']
                //                                         }}
                //                                         onClick={(e) => {
                //                                             let numbers = firstNoBlue;

                //                                             if (numbers.includes(i)) {
                //                                                 numbers = numbers.filter(e => e !== i)
                //                                                 document.getElementById("props_blue" + props.index + "number_" + i).classList.add('not_selected');
                //                                                 document.getElementById("props_blue" + props.index + "number_" + i).classList.remove("selected_number");

                //                                             }
                //                                             else if (numbers.length === 0) {

                //                                                 numbers.push(i)
                //                                                 document.getElementById("props_blue" + props.index + "number_" + i).classList.add('selected_number');
                //                                                 document.getElementById("props_blue" + props.index + "number_" + i).classList.remove("not_selected");
                //                                                 // setFirstNumber("props_blue" + props.index + "number_" + i)

                //                                             }
                //                                             else {
                //                                                 if (numbers.length < 1) {
                //                                                     // alert("selected_number" + i)
                //                                                     numbers.push(i)
                //                                                     document.getElementById("props_blue" + props.index + "number_" + i).classList.add('selected_number');
                //                                                     document.getElementById("props_blue" + props.index + "number_" + i).classList.remove("not_selected");
                //                                                 }
                //                                             }

                //                                             setFirstNoBlue(numbers)
                //                                             // console.log(numbers)
                //                                             let payloads = {
                //                                                 index: props.index,

                //                                             };

                //                                             setNumberChecker1(!numberChecker1)
                //                                         }
                //                                         }
                //                                     >
                //                                         {i}
                //                                     </Grid>
                //                                 );
                //                             }
                //                             return arr;
                //                         })()}
                //                     </Grid>

                //                     <br />
                //                     <Grid
                //                         container
                //                         justifyContent="center"
                //                         style={{
                //                             backgroundColor: ConstantStyles['white'],
                //                             textAlign: "center",
                //                             padding: "5%",
                //                             borderTop: ConstantStyles['black-border']
                //                         }} >
                //                         <p style={{
                //                             fontSize: "13px",
                //                             textAlign: "left",
                //                             width: "100%",
                //                             fontWeight: ConstantStyles['bold']
                //                         }}>Single Bet</p>
                //                         <p style={{
                //                             fontSize: "13px",
                //                             textAlign: "center",
                //                             width: "100%",
                //                             padding: "0% 0 1% 0%"
                //                         }}>
                //                             {selectedSecond.length > 0 ?

                //                                 selectedSecond.map((first, index) =>
                //                                 (
                //                                     <div
                //                                         key={index}
                //                                         style={{
                //                                             backgroundColor: "#f8b12c",
                //                                             color: "#fff",
                //                                             fontSize: "13px",
                //                                             padding: "3% 1%",
                //                                             height: "100%",
                //                                             borderRadius: "50%",
                //                                             width: "10%",
                //                                             display: "inline-block"
                //                                         }}
                //                                     >{first}</div>
                //                                 )
                //                                 )

                //                                 :
                //                                 num2.map((first, index) =>
                //                                 (
                //                                     <div
                //                                         key={index}
                //                                         style={{
                //                                             backgroundColor: "#f8b12c",
                //                                             color: "#fff",
                //                                             fontSize: "13px",
                //                                             padding: "3% 1%",
                //                                             height: "100%",
                //                                             borderRadius: "50%",
                //                                             width: "10%",
                //                                             display: "inline-block"
                //                                         }}
                //                                     >-</div>
                //                                 )
                //                                 )

                //                             }
                //                         </p>
                //                         {(() => {
                //                             const arr = [];
                //                             for (let i = 1; i < count2; i++) {

                //                                 let new_color = secondNo.includes(i) ? "selected_number_l" : "not_selected";
                //                                 arr.push(
                //                                     <Grid item xs={1} sm={1.3}
                //                                         id={`props2_${props.index}number2_${i}`}
                //                                         key={`key2_${i}`}
                //                                         className={`clear_${i} ${new_color}`}
                //                                         sx={{
                //                                             border: ConstantStyles["black-border"],
                //                                             cursor: "pointer",
                //                                             fontSize: "13px",
                //                                             // borderRadius: "50%",
                //                                             margin: "1px",
                //                                             fontSize: "13px",
                //                                             padding: "1% 1%",
                //                                             height: "34%",
                //                                             width: "13%",
                //                                             display: "inline-block",
                //                                         }}

                //                                         onClick={(e) => {
                //                                             let numbers = secondNo;
                //                                             if (numbers.includes(i)) {
                //                                                 numbers = numbers.filter(e => e !== i)
                //                                                 document.getElementById("props2_" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.white;
                //                                                 document.getElementById("props2_" + props.index + "number2_" + i).style.color = ConstantStyles.black;
                //                                             }
                //                                             else {
                //                                                 if (numbers.length === 0) {
                //                                                     numbers.push(i)
                //                                                     document.getElementById("props2_" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.secondary;
                //                                                     document.getElementById("props2_" + props.index + "number2_" + i).style.color = ConstantStyles.white;
                //                                                 }
                //                                             }
                //                                             setSecondNo(numbers)
                //                                             setNumberChecker1(!numberChecker1)
                //                                         }
                //                                         }
                //                                     >
                //                                         {i}
                //                                     </Grid>
                //                                 );
                //                             }
                //                             return arr;
                //                         })()}
                //                     </Grid>


                //                     <Grid
                //                         container
                //                         justifyContent="center"
                //                         style={{
                //                             backgroundColor: ConstantStyles['white'],
                //                             padding: "1%",
                //                             borderTop: ConstantStyles['black-border'],
                //                             minHeight: "90px"
                //                         }} >

                //                         <p style={{
                //                             fontSize: "13px",
                //                             textAlign: "left",
                //                             width: "100%",
                //                             padding: "0% 0 1% 5%",
                //                             fontWeight: ConstantStyles['bold']
                //                         }}>ODD-EVEN BET: {oddEvenBet}</p>
                //                         {oddEvenData.map((col, i) =>
                //                         (

                //                             <Grid item key={i} xs={12} sm={2} m={1}
                //                                 style={col.value === oddEvenBet ?
                //                                     {
                //                                         border: ConstantStyles['black-border'],
                //                                         fontWeight: ConstantStyles['medium-bold'],
                //                                         textAlign: "center",
                //                                         backgroundColor: ConstantStyles['light-grey']
                //                                     } : {
                //                                         border: ConstantStyles['black-border'],
                //                                         fontWeight: ConstantStyles['medium-bold'],
                //                                         textAlign: "center"
                //                                     }
                //                                 }
                //                                 id={`oddeven${props.index}number2_${i}`}
                //                                 className={oddEvenBetStyle}
                //                                 onClick={(e) => {
                //                                     let oddeventValue = col.value;
                //                                     if (oddEvenBet === oddeventValue) {
                //                                         // oddeventValue = oddeventValue.filter(e => e !== i)
                //                                         document.getElementById("oddeven" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.white;
                //                                         document.getElementById("oddeven" + props.index + "number2_" + i).style.color = ConstantStyles.black;
                //                                         setOddEvenBet("")
                //                                         setOddEvenIndex("")
                //                                     }
                //                                     else {
                //                                         if (oddEvenBet === "") {
                //                                             setOddEvenBet(col.value)
                //                                             setOddEvenIndex(i)
                //                                             document.getElementById("oddeven" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.grey;
                //                                             document.getElementById("oddeven" + props.index + "number2_" + i).style.color = ConstantStyles.white;
                //                                         }

                //                                         else if (oddEvenBet !== oddeventValue) {
                //                                             document.getElementById("oddeven" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.grey;
                //                                             document.getElementById("oddeven" + props.index + "number2_" + oddEvenIndex).style.backgroundColor = ConstantStyles.white;
                //                                             setOddEvenBet(col.value)
                //                                             setOddEvenIndex(i)
                //                                         }
                //                                     }

                //                                 }}
                //                             >
                //                                 <small style={{
                //                                     fontSize: "13px",
                //                                     cursor: "pointer",
                //                                     color: ConstantStyles['almost-red'],
                //                                 }}
                //                                 >{col.red}</small>
                //                                 <small
                //                                     style={{
                //                                         fontSize: "13px",
                //                                         cursor: "pointer",
                //                                         color: ConstantStyles['almost-blue'],

                //                                     }}>-</small>
                //                                 <small style={{
                //                                     fontSize: "13px",
                //                                     cursor: "pointer",
                //                                     color: ConstantStyles['almost-blue'],
                //                                 }}
                //                                 >{col.blue}</small>
                //                             </Grid>
                //                         )
                //                         )}

                //                     </Grid>

                //                     <Grid
                //                         container
                //                         justifyContent="center"
                //                         style={{
                //                             backgroundColor: ConstantStyles['white'],
                //                             padding: "1%",
                //                             borderTop: ConstantStyles['black-border'],
                //                             minHeight: "90px"
                //                         }} >

                //                         <p style={{
                //                             fontSize: "13px",
                //                             textAlign: "left",
                //                             width: "100%",
                //                             padding: "0% 0 1% 5%",
                //                             fontWeight: ConstantStyles['bold']
                //                         }}>RANGE BETSS: {rangeBet}</p>

                //                         {
                //                             rangeBetData.map((col, i) =>
                //                             (
                //                                 < Grid item key={i} xs={12} sm={2} m={1}
                //                                     style={col.value === rangeBet ?
                //                                         {
                //                                             border: ConstantStyles['black-border'],
                //                                             fontWeight: ConstantStyles['medium-bold'],
                //                                             textAlign: "center",
                //                                             backgroundColor: ConstantStyles['light-grey']
                //                                         } : {
                //                                             border: ConstantStyles['black-border'],
                //                                             fontWeight: ConstantStyles['medium-bold'],
                //                                             textAlign: "center"
                //                                         }
                //                                     }
                //                                     id={`range${props.index}number2_${i}`}
                //                                     onClick={(e) => {
                //                                         let rangetValue = col.value;
                //                                         // console.log(rangeBet)
                //                                         if (rangeBet === rangetValue) {
                //                                             // rangetValue = rangetValue.filter(e => e !== i)
                //                                             document.getElementById("range" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.white;
                //                                             document.getElementById("range" + props.index + "number2_" + i).style.color = ConstantStyles.black;
                //                                             setRangeBet("")
                //                                             setRangeIndex("")
                //                                         }
                //                                         else {
                //                                             if (rangeBet === "") {
                //                                                 setRangeBet(col.value)
                //                                                 setRangeIndex(i)
                //                                                 document.getElementById("range" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.grey;
                //                                                 document.getElementById("range" + props.index + "number2_" + i).style.color = ConstantStyles.white;
                //                                             }
                //                                             else if (rangeBet !== rangetValue) {
                //                                                 document.getElementById("range" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.grey;
                //                                                 document.getElementById("range" + props.index + "number2_" + rangeIndex).style.backgroundColor = ConstantStyles.white;
                //                                                 setRangeBet(col.value)
                //                                                 setRangeIndex(i)
                //                                             }
                //                                         }

                //                                     }}
                //                                 >
                //                                     <small style={{
                //                                         fontSize: "13px",
                //                                         cursor: "pointer",
                //                                         color: ConstantStyles['almost-red'],
                //                                     }}
                //                                     >{col.red}</small>
                //                                     <small
                //                                         style={{
                //                                             fontSize: "13px",
                //                                             cursor: "pointer",
                //                                             color: ConstantStyles['almost-blue'],

                //                                         }}> | </small>
                //                                     <small style={{
                //                                         fontSize: "13px",
                //                                         cursor: "pointer",
                //                                         color: ConstantStyles['almost-blue'],
                //                                     }}
                //                                     >{col.blue}</small>
                //                                 </Grid>
                //                             )
                //                             )
                //                         }
                //                     </Grid>
                //                 </Card>
                //             </Grid >
                //         </DialogContent>
                //         <DialogActions>
                //             {/* <Button onClick={handleClose}>Disagree</Button> */}
                //             <Button onClick={handleClose}>
                //                 Continue
                //             </Button>
                //         </DialogActions>
                //     </Dialog>
                // </Grid>
                null
                :
                < Grid item xs={12} sm={5.7} mt={2} mb={2}

                    sx={{
                        maxWidth: { xs: "100%", sm: "auto" },
                        padding: "0%",
                        minHeight: "500.20px",
                        borderRadius: "12px",
                        border: "2px solid #222"
                    }}
                    ml={{ xs: 0, sm: 0, md: 1.5, lg: 1.5, xl: 1.5 }}
                    mr={{ xs: 0, sm: 0, md: 1.5, lg: 1.5, xl: 1.5 }}
                >


                    <Grid
                        container
                        spacing={0}
                        p={{ xs: 2, sm: 0 }}
                        style={{
                            borderRadius: "15px",
                            backgroundColor: ConstantStyles['white'],
                        }}

                    >
                        < Grid item xs={12} sm={8}>
                            <Card
                                sx={{
                                    maxWidth: "100%",
                                    backgroundColor: ConstantStyles.white,
                                    color: ConstantStyles['dark-grey'],
                                    padding: "3% 0%",
                                    borderRight: "2px solid",
                                    // boxShadow: "unset"
                                    borderRadius: "0px"
                                }}
                            >
                                <div>
                                    <Grid container >
                                        <Grid item xs={12} sm={5} sx={{
                                        }}>
                                            <p style={{
                                                fontSize: "18px",
                                                textAlign: "left",
                                                width: "100%",
                                                padding: "0% 0 0 5%",
                                                fontWeight: ConstantStyles['bold']
                                            }}>STRAIGHT BET:</p>
                                            <p style={{
                                                fontSize: "16px",
                                                textAlign: "left",
                                                width: "100%",
                                                padding: "0% 0 1% 5%"
                                            }}> Pick a 2-number combination 1 from the red column and 1 from blue.</p>
                                        </Grid>
                                        <Grid item xs={12} sm={6.5} sx={{
                                            textAlign: "right"
                                        }}>
                                            <br />
                                            <button style={{
                                                fontSize: "22px",
                                                textTransform: "uppercase",
                                                cursor: "pointer",
                                                background: ConstantStyles.primary,
                                                color: ConstantStyles['white'],
                                                padding: "2% 5%",
                                                border: "5px solid rgb(76, 194, 235)",
                                                borderRadius: "5px",
                                                display: "inline-block"
                                            }}
                                                onClick={(e) => {
                                                    fetchRandom("singleRandom")
                                                }}
                                            >Lucky Pick</button>
                                            <button style={{
                                                fontSize: "22px",
                                                textTransform: "uppercase",
                                                cursor: "pointer",
                                                background: ConstantStyles['almost-red'],
                                                color: ConstantStyles['white'],
                                                marginLeft: "5px",
                                                padding: "2%",
                                                border: "5px solid rgb(76, 194, 235)",
                                                borderRadius: "5px",
                                                display: "inline-block"
                                            }}
                                                onClick={(e) => {
                                                    handleClearTickets("singleRandom")
                                                }}
                                            >Clear</button>

                                        </Grid>
                                        <Grid item xs={12} sm={0.5} sx={{
                                            textAlign: "center"
                                        }}>
                                        </Grid>
                                    </Grid>
                                </div>
                                <p style={{
                                    fontSize: "12px",
                                    textAlign: "center",
                                    width: "100%",
                                    paddingTop: "2px",
                                    padding: "0% 0 1% 0%"
                                }}>
                                    {selectedFirstRed.length > 0 ?

                                        selectedFirstRed.map((first, index) =>
                                        (
                                            <div
                                                key={index}
                                                style={{
                                                    color: "#222",
                                                    fontSize: "14px",
                                                    padding: "2% 1%",
                                                    backgroundRepeat: "round",
                                                    width: "10%",
                                                    display: "inline-block",
                                                    marginRight: "5px",
                                                    fontWeight: "bold",
                                                    backgroundImage: `url(${ball_red})`
                                                }}
                                            >{first}</div>
                                        )
                                        )

                                        :
                                        num.map((first, index) =>
                                        (
                                            <div
                                                key={index}
                                                style={{
                                                    color: "#222",
                                                    fontSize: "14px",
                                                    padding: "2% 1%",
                                                    backgroundRepeat: "round",
                                                    width: "10%",
                                                    display: "inline-block",
                                                    marginRight: "5px",
                                                    fontWeight: "bold",
                                                    backgroundImage: `url(${ball_red})`
                                                }}
                                            >{first}</div>
                                        )
                                        )

                                    }
                                    {selectedFirstBlue.length > 0 ?

                                        selectedFirstBlue.map((first, index) =>
                                        (
                                            <div
                                                key={index}
                                                style={{
                                                    // backgroundColor: ConstantStyles['almost-blue'],
                                                    color: "#222",
                                                    fontSize: "14px",
                                                    padding: "2% 1%",
                                                    backgroundRepeat: "round",
                                                    width: "10%",
                                                    display: "inline-block",
                                                    fontWeight: "bold",
                                                    marginRight: "5px",
                                                    backgroundImage: `url(${ball_blue})`
                                                }}
                                            >{first}
                                            </div>
                                        )
                                        )

                                        :
                                        num.map((first, index) =>
                                        (

                                            <div
                                                key={index}
                                                style={{
                                                    // backgroundColor: ConstantStyles['almost-blue'],
                                                    color: "#222",
                                                    fontSize: "14px",
                                                    padding: "2% 1%",
                                                    backgroundRepeat: "round",
                                                    width: "10%",
                                                    display: "inline-block",
                                                    fontWeight: "bold",
                                                    marginRight: "5px",
                                                    backgroundImage: `url(${ball_blue})`
                                                }}
                                            >{first}
                                            </div>
                                        )
                                        )

                                    }
                                </p>
                                <Grid
                                    container
                                    justifyContent="center"
                                    id={`number_${props.index}`}
                                    style={{
                                        textAlign: "center",
                                        padding: "1%",
                                        width: "45%",
                                        marginLeft: "5%",
                                        float: "left"
                                    }} >

                                    {(() => {
                                        const arr = [];
                                        for (let i = 1; i < count; i++) {

                                            let new_color = firstNoRed.includes(i) ? "selected_number" : "";
                                            arr.push(
                                                <Grid item xs={2} sm={2.5}
                                                    id={`props_red${props.index}number_${i}`}
                                                    key={`key_${i}`}
                                                    className={`clear_${i} ${new_color}`}
                                                    sx={{
                                                        border: ConstantStyles["black-border"],
                                                        cursor: "pointer",
                                                        fontSize: "14px",
                                                        margin: "2px",
                                                        backgroundColor: ConstantStyles['almost-red'],
                                                        color: ConstantStyles['white']
                                                    }}
                                                    onClick={(e) => {
                                                        let numbers = firstNoRed;
                                                        // numbers = numbers.filter(e => e !== i)
                                                        // document.getElementById("props_red" + props.index + "number_" + i).classList.add('not_selected');
                                                        // document.getElementById("props_red" + props.index + "number_" + i).classList.remove("selected_number");
                                                        // setFirstNoRed(numbers)
                                                        // if (numbers.includes(i)) {
                                                        //     numbers = numbers.filter(e => e !== i)
                                                        //     document.getElementById("props_red" + props.index + "number_" + i).classList.add('not_selected');
                                                        //     document.getElementById("props_red" + props.index + "number_" + i).classList.remove("selected_number");

                                                        // }
                                                        // else if (numbers.length === 0) {

                                                        //     numbers.push(i)
                                                        //     document.getElementById("props_red" + props.index + "number_" + i).classList.add('selected_number');
                                                        //     document.getElementById("props_red" + props.index + "number_" + i).classList.remove("not_selected");
                                                        //     // setFirstNumber("props_" + props.index + "number_" + i)

                                                        // }
                                                        // else {
                                                        //     if (numbers.length < 1) {
                                                        //         numbers.push(i)
                                                        //         document.getElementById("props_red" + props.index + "number_" + i).classList.add('selected_number');
                                                        //         document.getElementById("props_red" + props.index + "number_" + i).classList.remove("not_selected");
                                                        //     }
                                                        // }
                                                        if (numbers.includes(i) && numbers.length > 0) {

                                                            setFirstNoRed([])

                                                        }
                                                        else {
                                                            setFirstNoRed([i])
                                                        }

                                                        setNumberChecker1(!numberChecker1)
                                                    }
                                                    }
                                                >
                                                    {i}
                                                </Grid>
                                            );
                                        }
                                        return arr;
                                    })()}
                                </Grid>

                                <Grid
                                    container
                                    id={`number_${props.index}`}
                                    justifyContent="center"
                                    style={{
                                        textAlign: "center",
                                        padding: "1%",
                                        width: "45%",
                                        marginLeft: "5%",
                                    }} >

                                    {(() => {
                                        const arr = [];
                                        for (let i = 1; i < count; i++) {

                                            let new_color = firstNoBlue.includes(i) ? "selected_number" : "";
                                            arr.push(
                                                <Grid item xs={2} sm={2.5}
                                                    id={`props_blue${props.index}number_${i}`}
                                                    key={`key_${i}`}
                                                    className={`clear_${i} ${new_color}`}
                                                    sx={{
                                                        border: ConstantStyles["black-border"],
                                                        cursor: "pointer",
                                                        fontSize: "14px",
                                                        margin: "2px",
                                                        backgroundColor: ConstantStyles['almost-blue'],
                                                        color: ConstantStyles['white']
                                                    }}
                                                    onClick={(e) => {
                                                        let numbers = firstNoBlue;

                                                        // if (numbers.includes(i)) {
                                                        //     numbers = numbers.filter(e => e !== i)
                                                        //     document.getElementById("props_blue" + props.index + "number_" + i).classList.add('not_selected');
                                                        //     document.getElementById("props_blue" + props.index + "number_" + i).classList.remove("selected_number");

                                                        // }
                                                        // else if (numbers.length === 0) {

                                                        //     numbers.push(i)
                                                        //     document.getElementById("props_blue" + props.index + "number_" + i).classList.add('selected_number');
                                                        //     document.getElementById("props_blue" + props.index + "number_" + i).classList.remove("not_selected");
                                                        //     // setFirstNumber("props_blue" + props.index + "number_" + i)

                                                        // }
                                                        // else {
                                                        //     if (numbers.length < 1) {
                                                        //         // alert("selected_number" + i)
                                                        //         numbers.push(i)
                                                        //         document.getElementById("props_blue" + props.index + "number_" + i).classList.add('selected_number');
                                                        //         document.getElementById("props_blue" + props.index + "number_" + i).classList.remove("not_selected");
                                                        //     }
                                                        // }

                                                        // setFirstNoBlue(numbers)
                                                        // console.log(numbers)

                                                        if (numbers.includes(i) && numbers.length > 0) {

                                                            setFirstNoBlue([])

                                                        }
                                                        else {
                                                            setFirstNoBlue([i])
                                                        }
                                                        setNumberChecker1(!numberChecker1)
                                                    }
                                                    }
                                                >
                                                    {i}
                                                </Grid>
                                            );
                                        }
                                        return arr;
                                    })()}
                                </Grid>

                                <Grid
                                    container
                                    justifyContent="center"
                                    style={{
                                        backgroundColor: ConstantStyles['white'],
                                        padding: "5% 0"
                                    }} >
                                    <Grid item xs={6} sm={6} sx={{
                                        textAlign: "right"
                                    }}>
                                        <img src={payout_1280} width={"55%"} />

                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <div style={{ width: "100%", padding: "0 15px", position: "relative" }}>
                                            <div style={{ position: "relative" }}>
                                                {/* <span style={{ position: "absolute", textAlign: "right", fontSize: "20px" }}> Bet :</span> */}
                                                {/* <CustomTextField
                                                    // options={renderDraws}
                                                    type={"number"}
                                                    placeholder={"Bet Amount"}
                                                    step={"5"}
                                                    value={straigthBetAmt}
                                                    customSX={{
                                                        width: "50%",
                                                        display: "block",
                                                        textAlign: "center",
                                                        margin: "0 auto"
                                                    }}
                                                    onChange={setStraightBetAmt}
                                                /> */}

                                                <CustomAutocompleteSelect
                                                    options={renderDraws}
                                                    value={straigthBetAmt}
                                                    customSX={{
                                                        width: "50%"
                                                    }}
                                                    onChange={setStraightBetAmt}
                                                    placeholder={"Bet Amount"}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>

                                <br />
                                <Grid
                                    container
                                    justifyContent="center"
                                    sx={{
                                        backgroundColor: ConstantStyles['white'],
                                        textAlign: "center",
                                        padding: "5% 0",
                                        borderTop: ConstantStyles['black-border']
                                    }} >

                                    <Grid item xs={12} sm={5} sx={{
                                    }}>
                                        <p style={{
                                            fontSize: "18px",
                                            textAlign: "left",
                                            width: "100%",
                                            padding: "0% 0 0 5%",
                                            fontWeight: ConstantStyles['bold']
                                        }}>SINGLE BALL BET:</p>
                                        <p style={{
                                            fontSize: "16px",
                                            textAlign: "left",
                                            width: "100%",
                                            padding: "0% 0 1% 5%"
                                        }}> Pick 1 number<br /></p>
                                    </Grid>
                                    <Grid item xs={12} sm={6.5} sx={{
                                        textAlign: "right"
                                    }}>
                                        <button style={{
                                            fontSize: "22px",
                                            textTransform: "uppercase",
                                            cursor: "pointer",
                                            background: ConstantStyles.primary,
                                            color: ConstantStyles['white'],
                                            padding: "2% 5%",
                                            border: "5px solid rgb(76, 194, 235)",
                                            borderRadius: "5px",
                                            display: "inline-block"
                                        }}
                                            onClick={(e) => {
                                                fetchRandom("SingleBallBet")
                                            }}
                                        >Lucky Pick</button>
                                        <button style={{
                                            fontSize: "22px",
                                            textTransform: "uppercase",
                                            cursor: "pointer",
                                            background: ConstantStyles['almost-red'],
                                            color: ConstantStyles['white'],
                                            marginLeft: "5px",
                                            padding: "2%",
                                            border: "5px solid rgb(76, 194, 235)",
                                            borderRadius: "5px",
                                            display: "inline-block"
                                        }}
                                            onClick={(e) => {
                                                handleClearTickets("SingleBallBet")
                                            }}
                                        >Clear</button>

                                    </Grid>
                                    <Grid item xs={12} sm={0.5} sx={{
                                        textAlign: "center"
                                    }}>
                                    </Grid>
                                    <p style={{
                                        fontSize: "13px",
                                        textAlign: "center",
                                        width: "100%",
                                        padding: "0% 0 1% 0%",
                                        marginTop: "2%"
                                    }}>
                                        {selectedSecond.length > 0 ?

                                            selectedSecond.map((first, index) =>
                                            (
                                                <div
                                                    key={index}
                                                    style={{
                                                        color: "#222",
                                                        fontSize: "14px",
                                                        padding: "2.5%",
                                                        backgroundRepeat: "round",
                                                        width: "10%",
                                                        display: "inline-block",
                                                        fontWeight: "bold",
                                                        backgroundImage: `url(${ball_redblue})`
                                                    }}
                                                >{first}</div>
                                            )
                                            )

                                            :
                                            num2.map((first, index) =>
                                            (
                                                <div
                                                    key={index}
                                                    style={{
                                                        color: "#222",
                                                        fontSize: "14px",
                                                        padding: "2.5%",
                                                        backgroundRepeat: "round",
                                                        width: "10%",
                                                        display: "inline-block",
                                                        fontWeight: "bold",
                                                        backgroundImage: `url(${ball_redblue})`
                                                    }}

                                                >-</div>
                                            )
                                            )

                                        }
                                    </p>
                                    <Grid
                                        container
                                        justifyContent="center"
                                        style={{
                                            backgroundColor: ConstantStyles['white'],
                                            padding: "4%"
                                        }} >
                                        {(() => {
                                            const arr = [];
                                            for (let i = 1; i < count2; i++) {

                                                let new_color = secondNo.includes(i) ? "selected_number_l" : "not_selected";
                                                arr.push(
                                                    <Grid item xs={1} sm={1.3}
                                                        id={`props2_${props.index}number2_${i}`}
                                                        key={`key2_${i}`}
                                                        className={`clear_${i} ${new_color}`}
                                                        sx={{
                                                            border: ConstantStyles["black-border"],
                                                            cursor: "pointer",
                                                            // borderRadius: "50%",
                                                            margin: "1px",
                                                            fontSize: "13px",
                                                            padding: "1% 1%",
                                                            // height: "34%",
                                                            width: "13%",
                                                            display: "inline-block",
                                                            height: "33px"
                                                        }}

                                                        onClick={(e) => {
                                                            let numbers = secondNo;
                                                            // if (numbers.includes(i)) {
                                                            //     numbers = numbers.filter(e => e !== i)
                                                            //     document.getElementById("props2_" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.white;
                                                            //     document.getElementById("props2_" + props.index + "number2_" + i).style.color = ConstantStyles.black;
                                                            // }
                                                            // else {
                                                            //     if (numbers.length === 0) {
                                                            //         numbers.push(i)
                                                            //         document.getElementById("props2_" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.secondary;
                                                            //         document.getElementById("props2_" + props.index + "number2_" + i).style.color = ConstantStyles.white;
                                                            //     }
                                                            // }
                                                            // setSecondNo(numbers)

                                                            if (numbers.includes(i) && numbers.length > 0) {

                                                                setSecondNo([])

                                                            }
                                                            else {
                                                                setSecondNo([i])
                                                            }

                                                            setNumberChecker1(!numberChecker1)
                                                        }
                                                        }
                                                    >
                                                        {i}
                                                    </Grid>
                                                );
                                            }
                                            return arr;
                                        })()}
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    justifyContent="center"
                                    style={{
                                        backgroundColor: ConstantStyles['white'],
                                        paddingBottom: "10%",
                                        paddingTop: "1%"
                                    }} >
                                    <Grid item xs={6} sm={6} sx={{
                                        textAlign: "right"
                                    }}>

                                        <img src={payout_16_80} width={"55%"} />

                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <div style={{ width: "100%", padding: "0 15px" }}>
                                            <CustomAutocompleteSelect
                                                options={renderDraws}
                                                value={singleBallBet}
                                                customSX={{
                                                    width: "50%"
                                                }}
                                                onChange={setSingleBallBet}
                                                placeholder={"Bet Amount"}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>


                                <Grid container >

                                    <Grid item xs={12} sm={5} sx={{
                                    }}>
                                        <p style={{
                                            fontSize: "18px",
                                            textAlign: "left",
                                            width: "100%",
                                            padding: "0% 0 0 5%",
                                            fontWeight: ConstantStyles['bold']
                                        }}>RUMBLE BET:</p>
                                        <p style={{
                                            fontSize: "16px",
                                            textAlign: "left",
                                            width: "100%",
                                            padding: "0% 0 1% 5%"
                                        }}> Pick a 2-number combination, 1 from the red column and 1 from blue.</p>
                                    </Grid>
                                    <Grid item xs={12} sm={6.5} sx={{
                                        textAlign: "right"
                                    }}>
                                        <br />
                                        <button style={{
                                            fontSize: "22px",
                                            textTransform: "uppercase",
                                            cursor: "pointer",
                                            background: ConstantStyles.primary,
                                            color: ConstantStyles['white'],
                                            padding: "2% 5%",
                                            border: "5px solid rgb(76, 194, 235)",
                                            borderRadius: "5px",
                                            display: "inline-block"
                                        }}
                                            onClick={(e) => {
                                                fetchRandom("singleRandom2")
                                            }}
                                        >Lucky Pick</button>
                                        <button style={{
                                            fontSize: "22px",
                                            textTransform: "uppercase",
                                            cursor: "pointer",
                                            background: ConstantStyles['almost-red'],
                                            color: ConstantStyles['white'],
                                            marginLeft: "5px",
                                            padding: "2%",
                                            border: "5px solid rgb(76, 194, 235)",
                                            borderRadius: "5px",
                                            display: "inline-block"
                                        }}
                                            onClick={(e) => {
                                                handleClearTickets("singleRandom2")
                                            }}
                                        >Clear</button>

                                    </Grid>
                                    <Grid item xs={12} sm={0.5} sx={{
                                        textAlign: "center"
                                    }}>
                                    </Grid>

                                </Grid>
                                <p style={{
                                    fontSize: "12px",
                                    textAlign: "center",
                                    width: "100%",
                                    paddingTop: "2px",
                                    padding: "0% 0 1% 0%"
                                }}>
                                    {selectedSecondRed.length > 0 ?

                                        selectedSecondRed.map((first, index) =>
                                        (
                                            <div
                                                key={index}
                                                style={{

                                                    color: "#222",
                                                    fontSize: "14px",
                                                    padding: "2% 1%",
                                                    backgroundRepeat: "round",
                                                    width: "10%",
                                                    display: "inline-block",
                                                    fontWeight: "bold",
                                                    marginRight: "5px",
                                                    backgroundImage: `url(${ball_red})`
                                                }}
                                            >{first}</div>
                                        )
                                        )

                                        :
                                        num.map((first, index) =>
                                        (
                                            <div
                                                key={index}
                                                style={{

                                                    color: "#222",
                                                    fontSize: "14px",
                                                    padding: "2% 1%",
                                                    backgroundRepeat: "round",
                                                    width: "10%",
                                                    display: "inline-block",
                                                    fontWeight: "bold",
                                                    marginRight: "5px",
                                                    backgroundImage: `url(${ball_red})`
                                                }}
                                            >{first}</div>
                                        )
                                        )

                                    }
                                    {selectedSecondBlue.length > 0 ?

                                        selectedSecondBlue.map((first, index) =>
                                        (
                                            <div
                                                key={index}
                                                style={{

                                                    color: "#222",
                                                    fontSize: "14px",
                                                    padding: "2% 1%",
                                                    backgroundRepeat: "round",
                                                    width: "10%",
                                                    display: "inline-block",
                                                    fontWeight: "bold",
                                                    marginRight: "5px",
                                                    backgroundImage: `url(${ball_blue})`
                                                }}
                                            >{first}</div>
                                        )
                                        )

                                        :
                                        num.map((first, index) =>
                                        (
                                            <div
                                                key={index}
                                                style={{

                                                    color: "#222",
                                                    fontSize: "14px",
                                                    padding: "2% 1%",
                                                    backgroundRepeat: "round",
                                                    width: "10%",
                                                    display: "inline-block",
                                                    fontWeight: "bold",
                                                    marginRight: "5px",
                                                    backgroundImage: `url(${ball_blue})`
                                                }}
                                            >{first}</div>
                                        )
                                        )

                                    }
                                </p>

                                <Grid
                                    container
                                    id={`snumber_${props.index}`}
                                    justifyContent="center"
                                    style={{
                                        textAlign: "center",
                                        padding: "1%",
                                        width: "45%",
                                        marginLeft: "5%",
                                        float: "left"
                                    }} >

                                    {(() => {
                                        const arr = [];
                                        for (let i = 1; i < count; i++) {

                                            let new_color = secondNoRed.includes(i) ? "sselected_number" : "";
                                            arr.push(
                                                <Grid item xs={2} sm={2.5}
                                                    id={`sprops_red${props.index}snumber_${i}`}
                                                    key={`key_${i}`}
                                                    className={`clear_${i} ${new_color}`}
                                                    sx={{
                                                        border: ConstantStyles["black-border"],
                                                        cursor: "pointer",
                                                        fontSize: "14px",
                                                        margin: "2px",
                                                        backgroundColor: ConstantStyles['almost-red'],
                                                        color: ConstantStyles['white']
                                                    }}
                                                    onClick={(e) => {
                                                        let numbers = secondNoRed;

                                                        // if (numbers.includes(i)) {
                                                        //     numbers = numbers.filter(e => e !== i)
                                                        //     document.getElementById("sprops_red" + props.index + "snumber_" + i).classList.add('snot_selected');
                                                        //     document.getElementById("sprops_red" + props.index + "snumber_" + i).classList.remove("sselected_number");

                                                        // }
                                                        // else if (numbers.length === 0) {

                                                        //     numbers.push(i)
                                                        //     document.getElementById("sprops_red" + props.index + "snumber_" + i).classList.add('sselected_number');
                                                        //     document.getElementById("sprops_red" + props.index + "snumber_" + i).classList.remove("snot_selected");
                                                        //     // setFirstNumber("props_" + props.index + "number_" + i)

                                                        // }
                                                        // else {
                                                        //     if (numbers.length < 1) {
                                                        //         // alert("selected_number" + i)
                                                        //         numbers.push(i)
                                                        //         document.getElementById("sprops_red" + props.index + "snumber_" + i).classList.add('sselected_number');
                                                        //         document.getElementById("sprops_red" + props.index + "snumber_" + i).classList.remove("snot_selected");
                                                        //     }
                                                        // }

                                                        // setSecondNoRed(numbers)
                                                        // console.log(numbers)


                                                        if (numbers.includes(i) && numbers.length > 0) {

                                                            setSecondNoRed([])

                                                        }
                                                        else {
                                                            setSecondNoRed([i])
                                                        }


                                                        setNumberChecker1(!numberChecker1)
                                                    }
                                                    }
                                                >
                                                    {i}
                                                </Grid>
                                            );
                                        }
                                        return arr;
                                    })()}
                                </Grid>



                                <Grid
                                    container
                                    id={`snumber_${props.index}`}
                                    justifyContent="center"
                                    style={{
                                        textAlign: "center",
                                        padding: "1%",
                                        width: "45%",
                                        marginLeft: "5%",
                                    }} >

                                    {(() => {
                                        const arr = [];
                                        for (let i = 1; i < count; i++) {

                                            let new_color = secondNoBlue.includes(i) ? "sselected_number" : "";
                                            arr.push(
                                                <Grid item xs={2} sm={2.5}
                                                    id={`sprops_blue${props.index}snumber_${i}`}
                                                    key={`key_${i}`}
                                                    className={`clear_${i} ${new_color}`}
                                                    sx={{
                                                        border: ConstantStyles["black-border"],
                                                        cursor: "pointer",
                                                        fontSize: "14px",
                                                        margin: "2px",
                                                        backgroundColor: ConstantStyles['almost-blue'],
                                                        color: ConstantStyles['white']
                                                    }}
                                                    onClick={(e) => {
                                                        let numbers = secondNoBlue;

                                                        // if (numbers.includes(i)) {
                                                        //     numbers = numbers.filter(e => e !== i)
                                                        //     document.getElementById("sprops_blue" + props.index + "snumber_" + i).classList.add('snot_selected');
                                                        //     document.getElementById("sprops_blue" + props.index + "snumber_" + i).classList.remove("sselected_number");

                                                        // }
                                                        // else if (numbers.length === 0) {

                                                        //     numbers.push(i)
                                                        //     document.getElementById("sprops_blue" + props.index + "snumber_" + i).classList.add('sselected_number');
                                                        //     document.getElementById("sprops_blue" + props.index + "snumber_" + i).classList.remove("snot_selected");
                                                        //     // setFirstNumber("props_blue" + props.index + "number_" + i)

                                                        // }
                                                        // else {
                                                        //     if (numbers.length < 1) {
                                                        //         // alert("selected_number" + i)
                                                        //         numbers.push(i)
                                                        //         document.getElementById("sprops_blue" + props.index + "snumber_" + i).classList.add('sselected_number');
                                                        //         document.getElementById("sprops_blue" + props.index + "snumber_" + i).classList.remove("snot_selected");
                                                        //     }
                                                        // }

                                                        // setSecondNoBlue(numbers)
                                                        // console.log(numbers)


                                                        if (numbers.includes(i) && numbers.length > 0) {

                                                            setSecondNoBlue([])

                                                        }
                                                        else {
                                                            setSecondNoBlue([i])
                                                        }

                                                        setNumberChecker1(!numberChecker1)
                                                    }
                                                    }
                                                >
                                                    {i}
                                                </Grid>
                                            );
                                        }
                                        return arr;
                                    })()}
                                </Grid>


                                <Grid
                                    container
                                    justifyContent="center"
                                    style={{
                                        backgroundColor: ConstantStyles['white'],
                                        padding: "5% 0"
                                    }} >
                                    <Grid item xs={0} sm={6} sx={{
                                        textAlign: "right"
                                    }}>

                                        <img src={payout_640} width={"55%"} />
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <div style={{ width: "100%", padding: "0 15px" }}>
                                            <CustomAutocompleteSelect
                                                options={renderDraws}
                                                value={rumbleBetAmt}
                                                customSX={{
                                                    width: "50%"
                                                }}
                                                onChange={setBetAmt}
                                                placeholder={"Bet Amount"}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>


                            </Card>

                        </Grid>
                        < Grid item xs={12} sm={4} sx={{ height: "100%" }}>
                            <Card
                                sx={{
                                    maxWidth: "100%",
                                    backgroundColor: ConstantStyles.white,
                                    color: ConstantStyles['dark-grey'],
                                    border: "0px",
                                    boxShadow: "unset"
                                }}
                            >

                                <Grid
                                    container
                                    justifyContent="center"
                                    style={{
                                        backgroundColor: ConstantStyles['white'],
                                        padding: "1%",
                                        minHeight: "90px"
                                    }} >

                                    <p style={{
                                        fontSize: "22px",
                                        textAlign: "center",
                                        width: "100%",
                                        paddingTop: "5%",
                                        fontWeight: ConstantStyles['bold']
                                    }}>ODD-EVEN BET:</p>

                                    <div style={{ width: "100%", display: "block", verticalAlign: "top", margin: "10% auto", textAlign: "center" }}>

                                        <small style={{
                                            fontSize: "13px",
                                            cursor: "pointer",
                                            background: ConstantStyles.primary,
                                            color: ConstantStyles['white'],
                                            padding: "5%",
                                            border: "4px solid rgb(76, 194, 235)",
                                            borderRadius: "5px"
                                        }}
                                            onClick={(e) => {
                                                fetchRandom("oddevenRandom")
                                            }}
                                        >Lucky Pick</small>

                                        <small style={{
                                            fontSize: "13px",
                                            cursor: "pointer",
                                            background: ConstantStyles['almost-red'],
                                            color: ConstantStyles['white'],
                                            marginLeft: "5px",
                                            padding: "5%",
                                            border: "4px solid rgb(76, 194, 235)",
                                            borderRadius: "5px"
                                        }}
                                            onClick={(e) => {
                                                handleClearTickets("oddevenRandom")
                                            }}
                                        >Clear</small>
                                    </div>

                                    <br />

                                    {oddEvenData.map((col, i) =>
                                    (

                                        <Grid item key={i} xs={12} sm={12} m={1} sx={{
                                            fontWeight: ConstantStyles['medium-bold'],
                                            textAlign: "center",
                                            // position: "relative",
                                            display: "block"
                                        }}
                                            id={`oddeven${props.index}number2_${i}`}
                                            className={oddEvenBetStyle}
                                            onClick={(e) => {
                                                let oddeventValue = col.value;
                                                if (oddEvenBet === oddeventValue) {
                                                    // oddeventValue = oddeventValue.filter(e => e !== i)
                                                    // document.getElementById("oddeven" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.white;
                                                    // document.getElementById("oddeven" + props.index + "number2_" + i).style.color = ConstantStyles.black;
                                                    setOddEvenBet("")
                                                    setOddEvenIndex("")
                                                    setOddEvenAmt({ label: "0", value: 0 })
                                                }
                                                else {
                                                    if (oddEvenBet === "") {
                                                        setOddEvenBet(col.value)
                                                        setOddEvenIndex(i)
                                                        setOddEvenAmt({ label: side1Amt, value: side1Amt })
                                                        // document.getElementById("oddeven" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.grey;
                                                        // document.getElementById("oddeven" + props.index + "number2_" + i).style.color = ConstantStyles.white;
                                                    }

                                                    else if (oddEvenBet !== oddeventValue) {
                                                        // document.getElementById("oddeven" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.grey;
                                                        // document.getElementById("oddeven" + props.index + "number2_" + oddEvenIndex).style.backgroundColor = ConstantStyles.white;
                                                        setOddEvenBet(col.value)
                                                        setOddEvenIndex(i)
                                                    }
                                                }

                                            }}
                                        >
                                            <div style={{
                                                position: "relative", position: "relative", width: "50%", margin: "5% auto", cursor: "pointer"
                                            }}>
                                                <p style={{
                                                    width: "100%", height: "100%", fontWeight: ConstantStyles['medium-bold'], fontSize: "22px", lineHeight: "3", position: "absolute", backgroundColor: (oddEvenAmt?.value !== 0 && oddEvenBet !== "") ? "rgb(154 157 167 / 73%)" : ""
                                                }}>{(oddEvenAmt?.value !== 0 && oddEvenBet !== "") ? oddEvenAmt?.value : ""}</p>
                                                <img src={btn_oddeven} width={"100%"} />
                                            </div>
                                        </Grid>
                                    )
                                    )}
                                    {evenEvenData.map((col, i) =>
                                    (

                                        <Grid item key={i} xs={12} sm={12} m={1} style={{
                                            fontWeight: ConstantStyles['medium-bold'],
                                            textAlign: "center"
                                        }}
                                            id={`eveneven${props.index}number2_${i}`}
                                            className={oddEvenBetStyle}
                                            onClick={(e) => {
                                                let oddeventValue = col.value;
                                                if (evenEvenBet === oddeventValue) {
                                                    // oddeventValue = oddeventValue.filter(e => e !== i)
                                                    // document.getElementById("eveneven" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.white;
                                                    // document.getElementById("eveneven" + props.index + "number2_" + i).style.color = ConstantStyles.black;
                                                    setEvenEvenBet("")
                                                    setEvenEvenIndex("")
                                                    setEvenEvenAmt({ label: side1Amt, value: side1Amt })
                                                }
                                                else {
                                                    if (evenEvenBet === "") {
                                                        setEvenEvenBet(col.value)
                                                        setEvenEvenIndex(i)
                                                        setEvenEvenAmt({ label: side1Amt, value: side1Amt })
                                                        // document.getElementById("eveneven" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.grey;
                                                        // document.getElementById("eveneven" + props.index + "number2_" + i).style.color = ConstantStyles.white;
                                                    }

                                                    else if (evenEvenBet !== oddeventValue) {
                                                        // document.getElementById("eveneven" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.grey;
                                                        // document.getElementById("eveneven" + props.index + "number2_" + oddEvenIndex).style.backgroundColor = ConstantStyles.white;
                                                        setEvenEvenBet(col.value)
                                                        setEvenEvenIndex(i)
                                                    }
                                                }

                                            }}
                                        >
                                            {/* <p style={{
                                                width: "100%", position: "absolute"
                                            }}>{(evenEventAmt?.value !== 0 && evenEvenBet !== "") ? evenEventAmt?.value : ""}</p> */}


                                            <div style={{
                                                position: "relative", position: "relative", width: "50%", margin: "5% auto", cursor: "pointer"
                                            }}>
                                                <p style={{
                                                    width: "100%", height: "100%", fontWeight: ConstantStyles['medium-bold'], fontSize: "22px", lineHeight: "3", position: "absolute", backgroundColor: (evenEventAmt?.value !== 0 && evenEvenBet !== "") ? "rgb(154 157 167 / 73%)" : ""
                                                }}>{(evenEventAmt?.value !== 0 && evenEvenBet !== "") ? evenEventAmt?.value : ""}</p>
                                                <img src={btn_eveneven} width={"100%"} />
                                            </div>
                                        </Grid>
                                    )
                                    )}


                                    {oddOddData.map((col, i) =>
                                    (

                                        <Grid item key={i} xs={12} sm={12} m={1} style={{
                                            fontWeight: ConstantStyles['medium-bold'],
                                            textAlign: "center",
                                            position: "relative"
                                        }}
                                            id={`oddodd${props.index}number2_${i}`}
                                            className={oddEvenBetStyle}
                                            onClick={(e) => {
                                                let oddeventValue = col.value;
                                                if (oddOddBet === oddeventValue) {
                                                    // oddeventValue = oddeventValue.filter(e => e !== i)
                                                    // document.getElementById("oddodd" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.white;
                                                    // document.getElementById("oddodd" + props.index + "number2_" + i).style.color = ConstantStyles.black;
                                                    setOddOddBet("")
                                                    setEvenEvenIndex("")
                                                    setOddOddAmt({ label: side1Amt, value: side1Amt })
                                                }
                                                else {
                                                    if (oddOddBet === "") {
                                                        setOddOddBet(col.value)
                                                        setEvenEvenIndex(i)
                                                        setOddOddAmt({ label: side1Amt, value: side1Amt })
                                                        // document.getElementById("oddodd" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.grey;
                                                        // document.getElementById("oddodd" + props.index + "number2_" + i).style.color = ConstantStyles.white;
                                                    }

                                                    else if (oddOddBet !== oddeventValue) {
                                                        // document.getElementById("oddodd" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.grey;
                                                        // document.getElementById("oddodd" + props.index + "number2_" + oddEvenIndex).style.backgroundColor = ConstantStyles.white;
                                                        setOddOddBet(col.value)
                                                        setEvenEvenIndex(i)
                                                    }
                                                }

                                            }}
                                        >

                                            <div style={{
                                                position: "relative", position: "relative", width: "50%", margin: "5% auto", cursor: "pointer"
                                            }}>
                                                <p style={{
                                                    width: "100%", height: "100%", fontWeight: ConstantStyles['medium-bold'], fontSize: "22px", lineHeight: "3", position: "absolute", backgroundColor: (oddOddAmt?.value !== 0 && oddOddBet !== "") ? "rgb(154 157 167 / 73%)" : ""
                                                }}>{(oddOddAmt?.value !== 0 && oddOddBet !== "") ? oddOddAmt?.value : ""}</p>
                                                <img src={btn_oddodd} width={"100%"} />
                                            </div>
                                        </Grid>
                                    )
                                    )}

                                    {oddOddData1.map((col, i) =>
                                    (

                                        <Grid item key={i} xs={12} sm={12} m={1} style={{
                                            fontWeight: ConstantStyles['medium-bold'],
                                            textAlign: "center",
                                            position: "relative"
                                        }}
                                            id={`oddodds${props.index}number2_${i}`}
                                            className={oddEvenBetStyle}
                                            onClick={(e) => {
                                                let oddeventValue = col.value;
                                                if (oddOddBet1 === oddeventValue) {
                                                    // document.getElementById("oddodds" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.white;
                                                    // document.getElementById("oddodds" + props.index + "number2_" + i).style.color = ConstantStyles.black;
                                                    setOddOddBet1("")
                                                    setEvenEvenIndex("")
                                                    setOddOddAmt1({ label: side1Amt, value: side1Amt })
                                                }
                                                else {
                                                    if (oddOddBet1 === "") {
                                                        setOddOddBet1(col.value)
                                                        setEvenEvenIndex(i)
                                                        setOddOddAmt1({ label: side1Amt, value: side1Amt })
                                                        // document.getElementById("oddodds" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.grey;
                                                        // document.getElementById("oddodds" + props.index + "number2_" + i).style.color = ConstantStyles.white;
                                                    }

                                                    else if (oddOddBet1 !== oddeventValue) {
                                                        // document.getElementById("oddodds" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.grey;
                                                        // document.getElementById("oddodds" + props.index + "number2_" + oddEvenIndex).style.backgroundColor = ConstantStyles.white;
                                                        setOddOddBet1(col.value)
                                                        setEvenEvenIndex(i)
                                                    }
                                                }

                                            }}
                                        >
                                            <div style={{
                                                position: "relative", position: "relative", width: "50%", margin: "5% auto", cursor: "pointer"
                                            }}>
                                                <p style={{
                                                    width: "100%", height: "100%", fontWeight: ConstantStyles['medium-bold'], fontSize: "22px", lineHeight: "3", position: "absolute", backgroundColor: (oddOddAmt1?.value !== 0 && oddOddBet1 !== "") ? "rgb(154 157 167 / 73%)" : ""
                                                }}>{(oddOddAmt1?.value !== 0 && oddOddBet1 !== "") ? oddOddAmt1?.value : ""}</p>
                                                <img src={btn_evenodd} width={"100%"} />
                                            </div>
                                        </Grid>
                                    )
                                    )}

                                    <Grid
                                        container
                                        justifyContent="center"
                                        mt={3}
                                        sx={{
                                            backgroundColor: ConstantStyles['white'],
                                            padding: "0"
                                        }} >
                                        <Grid item xs={0} sm={5} sx={{
                                            textAlign: "center"
                                        }}>

                                            <img src={payout_3_50} />
                                            {/* <p style={{
                                                background: ConstantStyles.secondary,
                                                color: ConstantStyles['white'],
                                                padding: "10% 5%",
                                                borderRadius: "5px",
                                                fontSize: "13px",
                                                textTransform: "uppercase"
                                            }}
                                            >Payout 1:888</p> */}
                                        </Grid>
                                        <Grid item xs={6} sm={5}>
                                            <div style={{ width: "100%", padding: "0 15px" }}>
                                                <CustomAutocompleteSelect
                                                    options={renderDraws}
                                                    value={side1Amt}
                                                    customSX={{
                                                        width: "100%"
                                                    }}
                                                    onChange={(e) => {
                                                        setSide1Amt(e.value)
                                                    }
                                                    }
                                                    // onChange={setOddEvenAmt}
                                                    placeholder={"Bet Amount"}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    justifyContent="center"
                                    style={{
                                        backgroundColor: ConstantStyles['white'],
                                        padding: "1%",
                                        marginTop: "10%",
                                        minHeight: "90px"
                                    }} >

                                    <p style={{
                                        fontSize: "22px",
                                        textAlign: "center",
                                        width: "100%",
                                        paddingTop: "5%",
                                        fontWeight: ConstantStyles['bold']
                                    }}>RANGE BET: </p>

                                    <div style={{ width: "100%", display: "block", verticalAlign: "top", margin: "10% auto", textAlign: "center" }}>

                                        <small style={{
                                            fontSize: "13px",
                                            cursor: "pointer",
                                            background: ConstantStyles.primary,
                                            color: ConstantStyles['white'],
                                            padding: "5%",
                                            border: "4px solid rgb(76, 194, 235)",
                                            borderRadius: "5px"
                                        }}
                                            onClick={(e) => {
                                                fetchRandom("randomRange")
                                            }}
                                        >Lucky Pick</small>

                                        <small style={{
                                            fontSize: "13px",
                                            cursor: "pointer",
                                            background: ConstantStyles['almost-red'],
                                            color: ConstantStyles['white'],
                                            marginLeft: "5px",
                                            padding: "5%",
                                            border: "4px solid rgb(76, 194, 235)",
                                            borderRadius: "5px"
                                        }}
                                            onClick={(e) => {
                                                handleClearTickets("randomRange")
                                            }}
                                        >Clear</small>
                                    </div>
                                    {
                                        RangeBet1.map((col, i) =>
                                        (
                                            < Grid item key={i} xs={12} sm={12} m={1} style={{
                                                fontWeight: ConstantStyles['medium-bold'],
                                                textAlign: "center",
                                                position: "relative"

                                            }}
                                                id={`range1${props.index}number2_${i}`}
                                                onClick={(e) => {
                                                    let rangetValue = col.value;
                                                    // console.log(rangetValue)
                                                    // console.log(rangeBet)
                                                    if (rangebet1 === rangetValue) {
                                                        // rangetValue = rangetValue.filter(e => e !== i)
                                                        // document.getElementById("range1" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.white;
                                                        // document.getElementById("range1" + props.index + "number2_" + i).style.color = ConstantStyles.black;
                                                        setRangebet1("")
                                                        setRangeIndex("")
                                                        setRangebetAmt1({ label: side2Amt, value: side2Amt })

                                                    }

                                                    else {
                                                        if (rangebet1 === "") {
                                                            setRangebet1(col.value)
                                                            setRangeIndex(i)
                                                            setRangebetAmt1({ label: side2Amt, value: side2Amt })
                                                            // document.getElementById("range1" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.grey;
                                                            // document.getElementById("range1" + props.index + "number2_" + i).style.color = ConstantStyles.white;
                                                        }
                                                        else if (rangebet1 !== rangetValue) {
                                                            // document.getElementById("range1" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.grey;
                                                            // document.getElementById("range1" + props.index + "number2_" + rangeIndex).style.backgroundColor = ConstantStyles.white;
                                                            setRangeBet(col.value)
                                                            setRangeIndex(i)
                                                        }
                                                    }

                                                }}
                                            >
                                                {/* <p style={{
                                                    width: "100%", position: "absolute"
                                                }}>{(rangebetAmt1?.value !== 0 && rangebet1 !== "") ? rangebetAmt1?.value : ""}</p> */}
                                                <div style={{
                                                    position: "relative", position: "relative", width: "50%", margin: "5% auto", cursor: "pointer"
                                                }}>
                                                    <p style={{
                                                        width: "100%", height: "100%", fontWeight: ConstantStyles['medium-bold'], fontSize: "22px", lineHeight: "3", position: "absolute", backgroundColor: (rangebetAmt1?.value !== 0 && rangebet1 !== "") ? "rgb(154 157 167 / 73%)" : ""
                                                    }}>{(rangebetAmt1?.value !== 0 && rangebet1 !== "") ? rangebetAmt1?.value : ""}</p>
                                                    <img src={btn_1_19_19_19} width={"100%"} />
                                                </div>

                                            </Grid>
                                        )
                                        )
                                    }


                                    {
                                        RangeBet2.map((col, i) =>
                                        (
                                            < Grid item key={i} xs={12} sm={12} m={1} style={{
                                                fontWeight: ConstantStyles['medium-bold'],
                                                textAlign: "center",
                                                position: "relative"

                                            }}
                                                id={`range2${props.index}number2_${i}`}
                                                onClick={(e) => {
                                                    let rangetValue = col.value;
                                                    // console.log(rangetValue)
                                                    // console.log(rangeBet)
                                                    if (rangebet2 === rangetValue) {
                                                        // rangetValue = rangetValue.filter(e => e !== i)
                                                        // document.getElementById("range2" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.white;
                                                        // document.getElementById("range2" + props.index + "number2_" + i).style.color = ConstantStyles.black;
                                                        setRangebet2("")
                                                        setRangeIndex("")
                                                        setRangebetAmt2({ label: side2Amt, value: side2Amt })

                                                    }

                                                    else {
                                                        if (rangebet2 === "") {
                                                            setRangebet2(col.value)
                                                            setRangeIndex(i)
                                                            setRangebetAmt2({ label: side2Amt, value: side2Amt })
                                                            // document.getElementById("range2" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.grey;
                                                            // document.getElementById("range2" + props.index + "number2_" + i).style.color = ConstantStyles.white;
                                                        }
                                                        else if (rangebet2 !== rangetValue) {
                                                            // document.getElementById("range2" + props.index + "number2_" + i).style.backgroundColor = ConstantStyles.grey;
                                                            // document.getElementById("range2" + props.index + "number2_" + rangeIndex).style.backgroundColor = ConstantStyles.white;
                                                            setRangeBet(col.value)
                                                            setRangeIndex(i)
                                                        }
                                                    }

                                                }}
                                            >
                                                {/* <p style={{
                                                    width: "100%", position: "absolute"
                                                }}>{(rangebetAmt2?.value !== 0 && rangebet2 !== "") ? rangebetAmt2?.value : ""}</p>
                                                 */}
                                                <div style={{
                                                    position: "relative", position: "relative", width: "50%", margin: "5% auto", cursor: "pointer"
                                                }}>
                                                    <p style={{
                                                        width: "100%", height: "100%", fontWeight: ConstantStyles['medium-bold'], fontSize: "22px", lineHeight: "3", position: "absolute", backgroundColor: (rangebetAmt2?.value !== 0 && rangebet2 !== "") ? "rgb(154 157 167 / 73%)" : ""
                                                    }}>{(rangebetAmt2?.value !== 0 && rangebet2 !== "") ? rangebetAmt2?.value : ""}</p>
                                                    <img src={btn_1_19_20_38} width={"100%"} />
                                                </div>
                                            </Grid>
                                        )
                                        )
                                    }


                                    {
                                        RangeBet3.map((col, i) =>
                                        (
                                            < Grid item key={i} xs={12} sm={12} m={1} style={{
                                                fontWeight: ConstantStyles['medium-bold'],
                                                textAlign: "center",
                                                position: "relative"

                                            }}
                                                id={`range3${props.index}number3_${i}`}
                                                onClick={(e) => {
                                                    let rangetValue = col.value;
                                                    // console.log(rangetValue)
                                                    // console.log(rangeBet)
                                                    if (rangebet3 === rangetValue) {
                                                        // rangetValue = rangetValue.filter(e => e !== i)
                                                        // document.getElementById("range3" + props.index + "number3_" + i).style.backgroundColor = ConstantStyles.white;
                                                        // document.getElementById("range3" + props.index + "number3_" + i).style.color = ConstantStyles.black;
                                                        setRangebet3("")
                                                        setRangeIndex("")
                                                        setRangebetAmt3({ label: side2Amt, value: side2Amt })

                                                    }

                                                    else {
                                                        if (rangebet3 === "") {
                                                            setRangebet3(col.value)
                                                            setRangeIndex(i)
                                                            setRangebetAmt3({ label: side2Amt, value: side2Amt })
                                                            // document.getElementById("range3" + props.index + "number3_" + i).style.backgroundColor = ConstantStyles.grey;
                                                            // document.getElementById("range3" + props.index + "number3_" + i).style.color = ConstantStyles.white;
                                                        }
                                                        else if (rangebet3 !== rangetValue) {
                                                            // document.getElementById("range3" + props.index + "number3_" + i).style.backgroundColor = ConstantStyles.grey;
                                                            // document.getElementById("range3" + props.index + "number3_" + rangeIndex).style.backgroundColor = ConstantStyles.white;
                                                            setRangeBet(col.value)
                                                            setRangeIndex(i)
                                                        }
                                                    }

                                                }}
                                            >
                                                {/* <p style={{
                                                    width: "100%", position: "absolute"
                                                }}>{(rangebetAmt3?.value !== 0 && rangebet3 !== "") ? rangebetAmt3?.value : ""}</p>
                                                 */}
                                                <div style={{
                                                    position: "relative", position: "relative", width: "50%", margin: "5% auto", cursor: "pointer"
                                                }}>
                                                    <p style={{
                                                        width: "100%", height: "100%", fontWeight: ConstantStyles['medium-bold'], fontSize: "22px", lineHeight: "3", position: "absolute", backgroundColor: (rangebetAmt3?.value !== 0 && rangebet3 !== "") ? "rgb(154 157 167 / 73%)" : ""
                                                    }}>{(rangebetAmt3?.value !== 0 && rangebet3 !== "") ? rangebetAmt3?.value : ""}</p>
                                                    <img src={btn_20_38_20_38} width={"100%"} />
                                                </div>

                                                {/* 
                                            
                                            btn_20_38_1_19 */}
                                            </Grid>
                                        )
                                        )
                                    }


                                    {
                                        RangeBet4.map((col, i) =>
                                        (
                                            < Grid item key={i} xs={12} sm={12} m={1} style={{
                                                // border: ConstantStyles['black-border'],
                                                fontWeight: ConstantStyles['medium-bold'],
                                                textAlign: "center",
                                                position: "relative"

                                            }}
                                                id={`range4${props.index}number3_${i}`}
                                                onClick={(e) => {
                                                    let rangetValue = col.value;
                                                    // console.log(rangetValue)
                                                    // console.log(rangeBet)
                                                    if (rangebet4 === rangetValue) {
                                                        // rangetValue = rangetValue.filter(e => e !== i)
                                                        // document.getElementById("range4" + props.index + "number3_" + i).style.backgroundColor = ConstantStyles.white;
                                                        // document.getElementById("range4" + props.index + "number3_" + i).style.color = ConstantStyles.black;
                                                        setRangebet4("")
                                                        setRangeIndex("")
                                                        setRangebetAmt4({ label: side2Amt, value: side2Amt })

                                                    }

                                                    else {
                                                        if (rangebet4 === "") {
                                                            setRangebet4(col.value)
                                                            setRangeIndex(i)
                                                            setRangebetAmt4({ label: side2Amt, value: side2Amt })
                                                            // document.getElementById("range4" + props.index + "number3_" + i).style.backgroundColor = ConstantStyles.grey;
                                                            // document.getElementById("range4" + props.index + "number3_" + i).style.color = ConstantStyles.white;
                                                        }
                                                        else if (rangebet4 !== rangetValue) {
                                                            // document.getElementById("range4" + props.index + "number3_" + i).style.backgroundColor = ConstantStyles.grey;
                                                            // document.getElementById("range4" + props.index + "number3_" + rangeIndex).style.backgroundColor = ConstantStyles.white;
                                                            setRangeBet(col.value)
                                                            setRangeIndex(i)
                                                        }
                                                    }

                                                }}
                                            >
                                                <div style={{
                                                    position: "relative", position: "relative", width: "50%", margin: "5% auto", cursor: "pointer"
                                                }}>
                                                    <p style={{
                                                        width: "100%", height: "100%", fontWeight: ConstantStyles['medium-bold'], fontSize: "22px", lineHeight: "3", position: "absolute", backgroundColor: (rangebetAmt4?.value !== 0 && rangebet4 !== "") ? "rgb(154 157 167 / 73%)" : ""
                                                    }}>{(rangebetAmt4?.value !== 0 && rangebet4 !== "") ? rangebetAmt4?.value : ""}</p>
                                                    <img src={btn_20_38_1_19} width={"100%"} />
                                                </div>

                                                {/* 
                                            
                                             */}
                                            </Grid>
                                        )
                                        )
                                    }


                                    <Grid
                                        container
                                        justifyContent="center"
                                        style={{
                                            backgroundColor: ConstantStyles['white'],
                                            padding: "0"
                                        }} >
                                        <Grid item xs={0} sm={5} sx={{
                                            textAlign: "center"
                                        }}>

                                            <img src={payout_3_50} />
                                        </Grid>
                                        <Grid item xs={6} sm={5}>
                                            <div style={{ width: "100%", padding: "0 15px" }}>
                                                <CustomAutocompleteSelect
                                                    options={renderDraws}
                                                    value={side2Amt}
                                                    customSX={{
                                                        width: "100%"
                                                    }}
                                                    onChange={(e) => {
                                                        setSide2Amt(e.value)
                                                    }
                                                    }
                                                    placeholder={"Bet Amount"}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>

                    </Grid>
                </Grid >
            }
        </Fade >
    )


}


export default LotteryForms;