import { useState, useEffect, useMemo } from "react";
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from '../../../utils/GlobalStates';
import ConstantStyles from "../../../styles/ConstantStyles.json";
import LotterySection from './LotterySection';
import Header from '../../shared/general/Header';
import { useParams } from "react-router-dom";

const Homepage = ({ socket }) => {
    const globalStates = useHookState(GlobalStates);

    const [players, setPlayers] = useState([]);
    const [message, setMessage] = useState("");
    const [type, setType] = useState("");
    const [gif, setGif] = useState("");
    const { token, username, user_id } = useParams();
    useEffect(() => {
        globalStates.userName.set(username);
        globalStates.user_id.set(user_id);
    }, [token]);

    useEffect(() => {
        console.log(socket)
        socket.on("message", function (e) {
            setPlayers(e.players)
            setMessage(e.message)
            setType(e.type)
            setGif(e.gif)
        })
    }, [socket]);

    return (
        <div style={{ backgroundColor: ConstantStyles['white'] }}>
            <div style={{
                backgroundColor: ConstantStyles['white'],
                margin: "0 auto"
            }} >
                <Header />
                {token !== "" ?
                    <>
                        <LotterySection
                            players={players}
                            message={message}
                            type={type}
                            gif={gif}
                        />
                    </>
                    :
                    <p>Login Authentication Failed. Please login before opening the game</p>
                }
            </div >
        </div>
    )


}


export default Homepage;