import { TextField } from "@mui/material";
import PropTypes from "prop-types";
import ConstantStyles from "../../styles/ConstantStyles.json";

function CustomTextField(props) {
    return (
        <TextField
            id={`customtextfield-${props.id}`}
            sx={{
                "&.MuiFormControl-root": {
                    width: props.width
                },
                "& .MuiFilledInput-root": {
                    backgroundColor: `${props.disabled ? ConstantStyles["light-grey"] : ConstantStyles.white} !important`,
                    border: ConstantStyles["default-border"],
                    borderRadius: ConstantStyles["default-border-radius"],
                    color: ConstantStyles["default-text-color"],
                    padding: "5px 10px",
                    "& .MuiFilledInput-input": {
                        borderRadius: ConstantStyles["default-border-radius"],
                    },
                    "&.Mui-disabled": {
                        backgroundColor: `${ConstantStyles["light-grey"]} !important`
                    },
                    "&.Mui-focused": {
                        border: `1px solid ${ConstantStyles.primary}`
                    },
                    "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active": {
                        transition: "background-color 5000s ease-in-out 0s"
                    },
                    ...props.customSX
                },
                "& .MuiInputAdornment-root": {
                    margin: "0px 8px 0px 0px !important"
                }
            }}

            inputProps={{
                step: props.step,
                style: {
                    padding: "0px",
                    ...props.custominputProps,
                }
            }}
            InputProps={{
                disableUnderline: true,
                padding: "0px 12px 0px 0px",
                ...props.customInputProps,
            }}
            variant={props.variant}
            value={props.value}
            placeholder={props.placeholder}
            spellCheck={false}
            onChange={(event) => props.onChange(event.target.value, ...props.additionalParameters)}
            onKeyPress={props.onKeyPress}
            type={props.type}
            fullWidth={props.fullWidth}
            disabled={props.disabled}
            multiline={props.multiline}
            minRows={props.minRows}
            maxRows={props.maxRows}
        />
    )
}

CustomTextField.propTypes = {
    id: PropTypes.string,
    customSX: PropTypes.object,
    custominputProps: PropTypes.object,
    customInputProps: PropTypes.object,
    variant: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    type: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    width: PropTypes.string,
    multiline: PropTypes.bool,
    minRows: PropTypes.number,
    maxRows: PropTypes.number,
    step: PropTypes.string,
    additionalParameters: PropTypes.array
};

CustomTextField.defaultProps = {
    id: "",
    customSX: {},
    custominputProps: {},
    customInputProps: {},
    variant: "filled",
    value: "",
    placeholder: "Enter text here...",
    onChange: () => { },
    onKeyPress: () => { },
    type: "text",
    fullWidth: true,
    disabled: false,
    width: "100%",
    multiline: false,
    minRows: 4,
    maxRows: 4,
    step: "5",
    additionalParameters: []
};

export default CustomTextField;